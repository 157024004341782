import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import CustomProgressBar from "../../../components/customProgressBar/CustomProgressBar";
import { Content } from "../../layout/screens.layout";

const Header = styled.h3`
  font-weight: bold;
`;
const SubHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  h4 {
    font-size: 1.5rem;
    margin-bottom: 0;
  }
`;
interface Props {
  hasProcessing: number;
  lastProcessedBankStatus: string | undefined;
  onCompletedPercentages: () => void;
}
function WaitForBankData({
  hasProcessing,
  lastProcessedBankStatus,
  onCompletedPercentages,
}: Props) {
  const { t } = useTranslation();
  const theme: any = useTheme();
  return (
    <Content style={{ marginBottom: "2rem" }}>
      <Header
        className="with-custom-font"
        style={theme?.settings?.select?.texts?.processingScreen?.title?.styles}
      >
        {t("selectScreen.processingScreen.title")}
      </Header>
      <SubHeader>
        <h4>{t("selectScreen.processingScreen.subTitle")}</h4>
      </SubHeader>
      <CustomProgressBar
        totalTimeInMinutes={15}
        hasProcessing={hasProcessing}
        lastProcessedBankStatus={lastProcessedBankStatus}
        onCompletedPercentages={onCompletedPercentages}
      />
    </Content>
  );
}

export default WaitForBankData;
