export const getForFullDetailsParagraph = (
  text: string,
  lenderPrivacyPolicyLink: string,
  lenderName: string
) => {
  const replacements = [
    {
      search: "<0>",
      replace: lenderPrivacyPolicyLink
        ? '<a style={{...theme?.settings?.summary?.links?.styles,}} href="lenderPrivacyPolicyLink" target="_blank">'
        : "<span>",
    },
    { search: "</0>", replace: lenderPrivacyPolicyLink ? "</a>" : "</span>" },
  ];

  replacements.forEach((replacement) => {
    text = text.replace(replacement.search, replacement.replace);
  });

  if (lenderPrivacyPolicyLink) {
    text = text.replace("lenderPrivacyPolicyLink", lenderPrivacyPolicyLink);
  }

  return text.replaceAll("{{lenderName}}", lenderName);
};
