import { useState, useEffect } from "react";

import api from "../utils/api";
import { useError } from "./useError";
import { useOrchestrationToken } from "./useOrchestrationToken";

type Consumer = "person" | "company";

export type BootstrapResponse = {
  consumer: {
    email: string;
    firstName: string;
    middleName: string;
    lastName: string;
    phoneNumber: string;
    companyName: string;
    type: Consumer;
  };
  lender: {
    url: string;
    logoUrl: string;
    name: string;
    customerSupportEmail: string;
    enableStartingEmail: boolean;
    supportPage: string | null;
    showTopBanksOnly?: boolean;
    customThemeSettings: string | null;
    hideReturnToLenderButton: boolean;
    enableFuzzySearch: boolean;
    bankGroupsList?: Array<{
      groupCode: string;
      groupName: string;
      groupLogo: string;
    }>;
    singleConsentMode: boolean;
    lenderPrivacyPolicyLink: string;
    market: string;
  };
  orchestrationId: string;
  language: string;
  termsAccepted: boolean;
  processCompleted: boolean;
  prodEnvironment: boolean;
};

export type Translations = {
  translations: {
    [key: string]: {
      translation: object;
    };
  };
};

export type BootstrapResponseOrNull = BootstrapResponse | null;

const useBootstrap = () => {
  const [resources, setResources] = useState<BootstrapResponseOrNull>(null);
  const [error, setError] = useError();

  const orchestrationToken = useOrchestrationToken();

  useEffect(() => {
    fetchResources(orchestrationToken!)
      .then((res) => {
        setResources(res.data);
      })
      .catch((e) => setError(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { resources, error };
};

export const fetchResources = (
  orchestrationToken: string
): Promise<{ data: BootstrapResponse }> => {
  return api.get(`/api/orchestrations/${orchestrationToken}`);
};

// export const fetchTranslations = (
//   isInitRoute: boolean
// ): Promise<{ data: Translations }> => {
//   if (!isInitRoute) {
//     const cachedTranslations = getLocalStorageTranslations();
//     if (cachedTranslations) {
//       return Promise.resolve({ data: cachedTranslations });
//     }
//   }
//   return fetchTranslationsCall();
// };

// export const fetchTranslationsCall = (): Promise<{ data: Translations }> =>
//   api.get("/translations");

// const cacheTransltions = (translations: Translations) =>
//   window.localStorage.setItem("translations", JSON.stringify(translations));

// const clearCacheTranslations = () =>
//   window.localStorage.removeItem("translations");

// const getLocalStorageTranslations = (): Translations | null => {
//   const cachedTranslations = window.localStorage.getItem("translations");
//   if (cachedTranslations) {
//     return JSON.parse(cachedTranslations);
//   }
//   return null;
// };

export { useBootstrap };
