import React, { useContext } from "react";
import styled, { useTheme } from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import { Header } from "../../intro/components/IntroHeaderComponent";
import { getTextWithPopulatePlaceholders } from "../../../utils/helpers";
import { ResourcesContext } from "../../../context/consumer";
const HeaderContainer = styled.p`
  font-size: 2.4rem;
  font-weight: 500;
  margin: 0 !important;
`;

interface PermissionHeaderProps {
  bankName: string;
  bankLogo: string | undefined;
}

function PermissionHeader({ bankName, bankLogo }: PermissionHeaderProps) {
  const { t } = useTranslation();
  const theme: any = useTheme();
  const { resources } = useContext(ResourcesContext);
  return (
    <>
      <HeaderContainer id="consentPermission">
        <Header
          className="with-custom-font"
          color={theme.settings?.consent?.header?.title?.styles?.color}
          style={theme.settings?.consent?.header?.title?.styles}
        >
          {theme.settings?.consent?.header?.title?.text
            ? getTextWithPopulatePlaceholders(
                theme.settings.consent.header.title.text,
                resources
              )
            : t("consentScreen.permission")}
        </Header>
      </HeaderContainer>
      <div className="d-flex align-items-center">
        <img src={bankLogo} width="60" height="60" alt="" />
        <p className="font-weight-bold mb-0 ml-3">{bankName}</p>
      </div>
      <p
        id="consentHeader"
        style={{
          ...theme.settings?.consent?.header?.subTitle?.styles,
          color: theme.colors.text.toString(),
        }}
      >
        {theme.settings?.consent?.header?.subTitle?.text ? (
          getTextWithPopulatePlaceholders(
            theme.settings?.consent?.header?.subTitle?.text,
            resources,
            theme.settings?.consent?.header?.subTitle?.text?.dynamicPlaceholder
              ? bankName
              : null
          )
        ) : (
          <>
            <Trans
              i18nKey="consentScreen.header"
              tOptions={{ bankName, serviceName: "Experian Ireland" }}
            />

            {["en", "fr", "it"].includes(resources!.language) && (
              <Trans i18nKey="consentScreen.accessedOnlyOnce" />
            )}
          </>
        )}
      </p>
    </>
  );
}

export default PermissionHeader;
