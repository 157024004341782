import { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { useTheme, css } from "styled-components";
import { LoadableButton } from "../../../components/button/loadable-button.component";
import { ResourcesContext } from "../../../context/consumer";
import { useExitJourney } from "../../../hooks/useExitJourney";
import {
  getResource,
  getTextWithPopulatePlaceholders,
} from "../../../utils/helpers";
import { Footer, FooterContainer } from "../../layout/screens.layout";
import { useOrchestrationToken } from "../../../hooks/useOrchestrationToken";
import Modal from "../../../components/modal/Modal";
import { Button, ModalWrapper } from "../../select/select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { TextResourcesContext } from "../../../context/textResourcesContext";

const ActionButtonAnchor = styled(LoadableButton)`
  ${(props) =>
    props.theme.settings.intro.layout === "v1" &&
    css`
      width: 50%;
      max-width: calc(50% - 8px);
      margin-right: 1rem;
    `}

  ${(props) =>
    props.theme.settings.intro.layout === "v2" &&
    css`
      width: 100%;
      max-width: 90%;
      margin-bottom: 1rem;
    `}
  flex: 1;
  font-size: 1.7rem !important;

  @media (max-width: 320px) {
    padding: 0.8rem 1.8rem !important;
    font-size: 1.6rem !important;
  }
`;

const ButtonsFooter = styled(Footer)`
  ${(props) =>
    props.theme.settings.intro.layout === "v1" &&
    css`
      justify-content: flex-end;
      @media (max-width: 780px) {
        justify-content: center;
      }
    `}

  height: auto;
  padding: 1rem 0;

  ${(props) =>
    props.theme.settings.intro.layout === "v2" &&
    css`
      margin-bottom: 0 !important;
    `}
`;
const ButtonsFooterContainer = styled(FooterContainer)`
  ${(props) =>
    props.theme.settings.intro.layout === "v1" &&
    css`
      justify-content: flex-end;
      align-items: center;
      gap: 0.5rem;
      @media (max-width: 780px) {
        flex-direction: column;
        button {
          max-width: none;
          width: 100%;
          margin-right: 0;
        }
      }
    `}

  ${(props) =>
    props.theme.settings.intro.layout === "v2" &&
    css`
      margin-top: 0 !important;
    `}
`;
function ButtonsComponent({
  loadingAcceptTerms,
  acceptTerms,
  agreeTerms,
  loading,
}: any) {
  const { t } = useTranslation();
  const history = useHistory();
  const { resources } = useContext(ResourcesContext);
  const { data } = useContext(TextResourcesContext);
  const theme: any = useTheme();
  const orchestrationToken = useOrchestrationToken();
  const [showModal, setShowModal] = useState(false);
  const modal = useRef(null);
  const { execute: exitJourney, loading: exitJourneyLoading } =
    useExitJourney(orchestrationToken);
  return (
    <>
      <ButtonsFooter
        className={`mt-4 ${theme.settings.intro.layout == "v2" && "bg-none"}`}
      >
        <ButtonsFooterContainer
          className={`${
            theme.settings.intro.layout == "v2" &&
            "align-items-center flex-column mt-5"
          }`}
        >
          {theme.settings?.intro?.declineServiceVisible && (
            <ActionButtonAnchor
              id="introExit"
              className={`${
                !theme?.colors?.buttons?.secondary ? " exp-outline-default" : ""
              } btn text-uppercase-first-letter`}
              onClick={() => setShowModal(true)}
              isLoading={exitJourneyLoading}
              disabled={exitJourneyLoading}
              variant={
                theme.settings.intro?.texts?.introExitButton?.type === "link"
                  ? "link"
                  : "secondary"
              }
            >
              {getResource(data, "CJ_introScreen_Exit_Button")}
            </ActionButtonAnchor>
          )}
          <ActionButtonAnchor
            id="introContinue"
            className={`${
              !theme?.colors?.buttons?.primary ? " exp-default" : ""
            } btn text-uppercase-first-letter`}
            onClick={acceptTerms}
            isLoading={loadingAcceptTerms}
            disabled={!agreeTerms || loading || loadingAcceptTerms}
            variant={
              theme.settings.intro?.texts?.introContinueButton?.type === "link"
                ? "link"
                : "primary"
            }
          >
            {getResource(data, "CJ_introScreen_Continue_Button")}
          </ActionButtonAnchor>
        </ButtonsFooterContainer>
      </ButtonsFooter>
      <Modal>
        <ModalWrapper className={showModal ? "visible" : ""} ref={modal}>
          <div className="modal-body">
            <div className="modal-header">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M18 25.42C17.8043 25.4172 17.6137 25.3571 17.4518 25.247C17.2899 25.137 17.1639 24.9818 17.0894 24.8008C17.0148 24.6198 16.9951 24.4209 17.0325 24.2288C17.0699 24.0367 17.163 23.8597 17.3 23.72L24.2 16.81L20.83 13.4C19.9599 12.5304 18.7801 12.0419 17.55 12.0419C16.3199 12.0419 15.1401 12.5304 14.27 13.4L11.55 16.12C11.3587 16.2838 11.1126 16.3694 10.8609 16.3597C10.6093 16.35 10.3705 16.2456 10.1924 16.0675C10.0143 15.8894 9.91 15.6507 9.90028 15.399C9.89056 15.1474 9.97617 14.9013 10.14 14.71L12.86 12C13.4757 11.3838 14.2068 10.8949 15.0116 10.5614C15.8163 10.2279 16.6789 10.0562 17.55 10.0562C18.4211 10.0562 19.2837 10.2279 20.0884 10.5614C20.8931 10.8949 21.6243 11.3838 22.24 12L26.36 16.12C26.5447 16.3062 26.6489 16.5576 26.65 16.82C26.6508 16.9516 26.6255 17.082 26.5758 17.2039C26.526 17.3257 26.4527 17.4365 26.36 17.53L18.75 25.14C18.651 25.2358 18.5333 25.3101 18.4042 25.3583C18.2752 25.4065 18.1376 25.4275 18 25.42Z" />
                <path d="M38 39.37H18C17.7348 39.37 17.4804 39.2646 17.2929 39.0771C17.1054 38.8896 17 38.6352 17 38.37V30.44L13.66 27.11C12.7868 26.2297 12.2968 25.04 12.2968 23.8C12.2968 22.56 12.7868 21.3703 13.66 20.49L19.16 14.99C19.3474 14.8038 19.6008 14.6992 19.865 14.6992C20.1292 14.6992 20.3826 14.8038 20.57 14.99C20.6637 15.083 20.7381 15.1936 20.7889 15.3154C20.8397 15.4373 20.8658 15.568 20.8658 15.7C20.8658 15.832 20.8397 15.9627 20.7889 16.0846C20.7381 16.2064 20.6637 16.317 20.57 16.41L15.1 21.9C14.5968 22.4043 14.3142 23.0876 14.3142 23.8C14.3142 24.5124 14.5968 25.1957 15.1 25.7L18.72 29.32C18.8631 29.4601 18.9577 29.6423 18.99 29.84C18.9949 29.9066 18.9949 29.9734 18.99 30.04V37.38H37V2.62H19V6.46C19 6.72522 18.8946 6.97958 18.7071 7.16711C18.5196 7.35465 18.2652 7.46 18 7.46C17.7348 7.46 17.4804 7.35465 17.2929 7.16711C17.1054 6.97958 17 6.72522 17 6.46V1.63C17 1.36479 17.1054 1.11043 17.2929 0.922898C17.4804 0.735362 17.7348 0.630005 18 0.630005H38C38.2652 0.630005 38.5196 0.735362 38.7071 0.922898C38.8946 1.11043 39 1.36479 39 1.63V38.37C39 38.6352 38.8946 38.8896 38.7071 39.0771C38.5196 39.2646 38.2652 39.37 38 39.37Z" />
                <path d="M6.99999 33.67C6.12874 33.6732 5.26552 33.5034 4.46042 33.1704C3.65532 32.8373 2.92437 32.3477 2.30999 31.73C2.20531 31.6403 2.12029 31.53 2.06027 31.4059C2.00024 31.2819 1.96651 31.1467 1.96119 31.009C1.95587 30.8713 1.97908 30.734 2.02936 30.6057C2.07963 30.4773 2.15589 30.3608 2.25334 30.2633C2.3508 30.1659 2.46734 30.0896 2.59567 30.0393C2.724 29.9891 2.86133 29.9659 2.99905 29.9712C3.13677 29.9765 3.2719 30.0102 3.39596 30.0702C3.52003 30.1303 3.63035 30.2153 3.71999 30.32C4.59008 31.1895 5.76987 31.678 6.99999 31.678C8.23012 31.678 9.4099 31.1895 10.28 30.32L11.55 29.05C11.737 28.8577 11.9927 28.7476 12.2609 28.7438C12.529 28.7401 12.7877 28.843 12.98 29.03C13.1723 29.217 13.2824 29.4727 13.2862 29.7408C13.2899 30.009 13.187 30.2677 13 30.46L11.7 31.73C11.0841 32.3485 10.3515 32.8385 9.54463 33.1715C8.7378 33.5046 7.87285 33.674 6.99999 33.67Z" />
                <path d="M32.22 21.84C31.3492 21.8405 30.4867 21.6694 29.682 21.3365C28.8773 21.0037 28.146 20.5155 27.53 19.9C27.3662 19.7087 27.2806 19.4626 27.2903 19.2109C27.3 18.9592 27.4044 18.7205 27.5824 18.5424C27.7605 18.3643 27.9993 18.26 28.251 18.2503C28.5026 18.2405 28.7487 18.3261 28.94 18.49C29.7016 19.249 30.7028 19.7207 31.7731 19.8246C32.8434 19.9285 33.9166 19.6583 34.81 19.06C35.0283 18.9448 35.2814 18.9144 35.5207 18.9747C35.76 19.0349 35.9686 19.1815 36.1063 19.3863C36.2441 19.591 36.3012 19.8395 36.2668 20.0838C36.2325 20.3282 36.1089 20.5512 35.92 20.71C34.828 21.4494 33.5388 21.8432 32.22 21.84Z" />
                <path d="M8.06999 10.78C7.87426 10.7772 7.68365 10.7171 7.52178 10.607C7.3599 10.497 7.23389 10.3418 7.15936 10.1608C7.08483 9.97983 7.06506 9.78093 7.1025 9.5888C7.13994 9.39667 7.23295 9.21975 7.36999 9.07998L10.55 5.88998L7.36999 2.70998C7.26531 2.62034 7.18029 2.51002 7.12026 2.38595C7.06024 2.26189 7.02651 2.12676 7.02119 1.98904C7.01587 1.85132 7.03908 1.71399 7.08935 1.58566C7.13963 1.45733 7.21589 1.34079 7.31334 1.24333C7.41079 1.14588 7.52734 1.06962 7.65567 1.01935C7.78399 0.969071 7.92132 0.945865 8.05904 0.951185C8.19676 0.956504 8.3319 0.990234 8.45596 1.05026C8.58002 1.11028 8.69034 1.1953 8.77999 1.29998L12.67 5.18998C12.8547 5.37624 12.9589 5.62764 12.96 5.88998C12.9608 6.02159 12.9355 6.15205 12.8858 6.27389C12.836 6.39573 12.7627 6.50654 12.67 6.59998L8.77999 10.49C8.68655 10.5827 8.57573 10.656 8.4539 10.7058C8.33206 10.7555 8.2016 10.7807 8.06999 10.78Z" />
                <path d="M11.76 6.85999H2C1.73478 6.85999 1.48043 6.75463 1.29289 6.56709C1.10536 6.37956 1 6.1252 1 5.85999C1 5.59477 1.10536 5.34041 1.29289 5.15288C1.48043 4.96534 1.73478 4.85999 2 4.85999H11.74C12.0052 4.85999 12.2596 4.96534 12.4471 5.15288C12.6346 5.34041 12.74 5.59477 12.74 5.85999C12.74 6.1252 12.6346 6.37956 12.4471 6.56709C12.2596 6.75463 12.0052 6.85999 11.74 6.85999H11.76Z" />
                <path d="M29.26 14.74C28.3225 14.74 27.4061 14.462 26.6266 13.9412C25.8471 13.4203 25.2396 12.6801 24.8808 11.8139C24.522 10.9478 24.4282 9.99475 24.6111 9.07528C24.794 8.15581 25.2454 7.31123 25.9083 6.64833C26.5712 5.98543 27.4158 5.53398 28.3353 5.35109C29.2547 5.1682 30.2078 5.26206 31.0739 5.62082C31.94 5.97958 32.6803 6.58712 33.2012 7.36661C33.722 8.1461 34 9.06253 34 10C33.9973 11.2563 33.4971 12.4604 32.6088 13.3488C31.7204 14.2371 30.5163 14.7374 29.26 14.74ZM29.26 7.26001C28.7181 7.26001 28.1883 7.42071 27.7377 7.72179C27.2871 8.02286 26.9359 8.45079 26.7286 8.95146C26.5212 9.45213 26.4669 10.0031 26.5726 10.5346C26.6784 11.0661 26.9393 11.5543 27.3225 11.9375C27.7057 12.3207 28.1939 12.5816 28.7254 12.6874C29.257 12.7931 29.8079 12.7388 30.3085 12.5314C30.8092 12.3241 31.2371 11.9729 31.5382 11.5223C31.8393 11.0717 32 10.5419 32 10C32 9.27332 31.7113 8.57639 31.1975 8.06254C30.6836 7.54869 29.9867 7.26001 29.26 7.26001Z" />
              </svg>

              <span
                className="close-btn"
                onClick={() => {
                  setShowModal(false);
                  document.body.style.overflow = "auto";
                }}
              >
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  size="lg"
                  color={
                    theme?.colors?.icons?.default ??
                    "var(--exp-dark-grey) !important"
                  }
                />
              </span>
            </div>
            <div className="d-flex flex-column w-100">
              <h4 className="font-weight-bold">
                <span className="modal-title">
                  {getResource(data, "CJ_introScreen_exitPopupTitle")}{" "}
                </span>
              </h4>
              <h4>
                <span className="modal-subtitle">
                  {getResource(data, "CJ_introScreen_exitPopupSubtitle")}
                </span>
              </h4>
            </div>
            <div className="btns-wrapper">
              <Button
                id="yesExit"
                isLoading={exitJourneyLoading}
                disabled={exitJourneyLoading}
                onClick={() => {
                  !resources?.lender.url
                    ? exitJourney(
                        () =>
                          history.push(
                            `/finish?orchestrationToken=${orchestrationToken}`,
                            { status: "fail" }
                          ),
                        "ServiceDeclinedByUser",
                        "ProcessingFailed"
                      )
                    : exitJourney(
                        () => window.open(resources?.lender.url, "_self"),
                        "ServiceDeclinedByUser",
                        "ProcessingFailed"
                      );
                }}
                variant={
                  theme.settings.intro?.texts?.introYesExitButton?.type ===
                  "link"
                    ? "link"
                    : "secondary"
                }
                className="btn text-uppercase-first-letter text-center bg-white btn-block"
              >
                {getResource(data, "CJ_introScreen_ExitPopupYes_Button")}
              </Button>
              <Button
                id="noExit"
                disabled={false}
                isLoading={false}
                onClick={() => {
                  setShowModal(false);
                  document.body.style.overflow = "auto";
                }}
                variant={
                  theme.settings.intro?.texts?.introNoExitButton?.type ===
                  "link"
                    ? "link"
                    : "primary"
                }
                className="btn text-uppercase-first-letter text-center bg-white btn-block"
              >
                {getResource(data, "CJ_introScreen_ExitPopupNo_Button")}
              </Button>
            </div>
          </div>
        </ModalWrapper>
      </Modal>
    </>
  );
}

export default ButtonsComponent;
