import React, { useContext } from "react";
import { Trans, useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import Collapsable from "../../../components/collapse/collapse.component";
import { ReactComponent as UserIcon } from "../../../assets/user.svg";
import { ReactComponent as PaymentsIcon } from "../../../assets/payments.svg";
import { ReactComponent as BusinessIcon } from "../../../assets/business.svg";
import { CollapsableIconHeader } from "../../consent";
import { getTextWithPopulatePlaceholders } from "../../../utils/helpers";
import { ResourcesContext } from "../../../context/consumer";

const Row = styled.div`
  margin-bottom: 1rem;
  padding: 0 1.2rem;
`;

const MoreInformationWrapper = styled.div`
  margin: 0 auto;
  white-space: pre-line;
`;

export const CollapsableIconContainer = styled.span`
  margin-right: 8px;
  & svg {
    margin-top: -4px;
    width: 100%;
    height: 100%;
    max-width: 20px;
    max-height: 20px;
  }
  height: 20px;
  width: 20px;
`;

const IconContainer = styled.span`
  display: inline-flex;
  margin-right: 8px;
  position: relative;
  top: 2px;
  & svg {
    width: 100%;
    height: 100%;
    max-width: 20px;
    max-height: 20px;
  }
  height: 20px;
  width: 20px;
`;
const BodyContainer = styled.span`
  margin-left: 0.5rem;
  font-size: 1.6rem;
`;

const HeaderContainer = styled.span`
  font-size: 1.6rem;
`;
function MoreInformationComponent({ lenderName }: any) {
  const { t } = useTranslation();
  const theme: any = useTheme();
  const { resources } = useContext(ResourcesContext);
  const settings = theme?.settings?.consent?.whatNeedToShareSection;

  const sectionItems = [
    <div>
      <p style={settings?.header?.styles ?? { fontWeight: "bold" }}>
        <span>{t("consentScreen.whyShareDataHeader")}</span>
      </p>
      <p className="mb-0">
        {theme.settings.consent?.texts?.whyShareDataBody?.[
          resources!.language
        ] ? (
          getTextWithPopulatePlaceholders(
            theme.settings.consent.texts.whyShareDataBody,
            resources,
            theme.settings?.consent?.texts?.whyShareDataBody?.dynamicPlaceholder
              ? lenderName
              : null
          )
        ) : (
          <span>{t("consentScreen.whyShareDataBody", { lenderName })}</span>
        )}
      </p>
      <p>
        <Trans
          i18nKey="consentScreen.returnDataBody"
          tOptions={{ lenderName }}
        ></Trans>
      </p>
    </div>,
    <div>
      <p style={settings?.header?.styles ?? { fontWeight: "bold" }}>
        <span>{t("consentScreen.whatShare")}</span>
      </p>
    </div>,

    <Row>
      <IconContainer
        style={{
          background: settings?.yourAccountHolderDetails?.icon ?? "none",
        }}
      >
        {!settings?.yourAccountHolderDetails?.icon && <UserIcon />}
      </IconContainer>
      <HeaderContainer
        style={settings?.header?.styles ?? { fontWeight: "bold" }}
      >
        {t("consentScreen.accountDetailsHeader")}
      </HeaderContainer>
      <BodyContainer>{t("consentScreen.accountDetailsBody")}</BodyContainer>
    </Row>,

    <Row>
      <IconContainer
        style={{ background: settings?.yourRegularPayments?.icon ?? "none" }}
      >
        {!settings?.yourRegularPayments?.icon && <PaymentsIcon />}
      </IconContainer>
      <HeaderContainer
        style={settings?.header?.styles ?? { fontWeight: "bold" }}
      >
        {t("consentScreen.accountPaymentsHeader")}
      </HeaderContainer>
      <BodyContainer>{t("consentScreen.accountPaymentsBody")}</BodyContainer>
    </Row>,
    <Row>
      <IconContainer
        style={{
          background: settings?.yourAccountTransactions?.icon ?? "none",
        }}
      >
        {!settings?.yourAccountTransactions?.icon && <BusinessIcon />}
      </IconContainer>
      <HeaderContainer
        style={settings?.header?.styles ?? { fontWeight: "bold" }}
      >
        {t("consentScreen.accountTransactionsHeader")}
      </HeaderContainer>

      <BodyContainer>
        {t("consentScreen.accountTransactionsBody")}
      </BodyContainer>
    </Row>,
  ];

  return <MoreInformationWrapper>{sectionItems}</MoreInformationWrapper>;
}

export default MoreInformationComponent;
