import { useState, useContext } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import styled, { useTheme } from "styled-components";
import { LoadableButton } from "../../../components/button/loadable-button.component";
import CustomProgressBar from "../../../components/customProgressBar/CustomProgressBar";
import { ResourcesContext } from "../../../context/consumer";
import { useLender } from "../../../hooks/useLender";
import { useOrchestrationToken } from "../../../hooks/useOrchestrationToken";
import { ButtonsFooterContainer } from "../../consent";
import { Content, Footer } from "../../layout/screens.layout";

const Header = styled.h3`
  font-weight: bold;
`;
const SubHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  h4 {
    font-size: 1.5rem;
    margin-bottom: 0;
  }
`;
interface Props {
  hasProcessing: boolean;
  onClick: () => void;
  onCompletedPercentages: () => void;
}

function WaitForSummaryData({
  hasProcessing,
  onClick,
  onCompletedPercentages,
}: Props) {
  const { t } = useTranslation();
  const [showProgressBar, setShowProgressBar] = useState(true);
  const { name: lenderName } = useLender();
  const { resources } = useContext(ResourcesContext);
  const orchestrationToken = useOrchestrationToken();
  const history = useHistory();
  const theme: any = useTheme();
  return (
    <Content style={{ marginBottom: "2rem" }}>
      <Header
        className="with-custom-font"
        style={theme?.settings?.summary?.texts?.processingScreen?.title?.styles}
      >
        {showProgressBar
          ? t("summaryScreen.processingScreen.processing.title")
          : t("summaryScreen.processingScreen.somethingWentWrong.title")}
      </Header>
      <SubHeader>
        <h4>
          {showProgressBar ? (
            t("summaryScreen.processingScreen.processing.subTitle")
          ) : (
            <Trans
              i18nKey="summaryScreen.processingScreen.somethingWentWrong.subTitle"
              tOptions={{
                lenderName,
              }}
            >
              <b></b>
            </Trans>
          )}
        </h4>
      </SubHeader>
      {!showProgressBar ? (
        <Footer className="mb-0 bg-none">
          <ButtonsFooterContainer className="justify-content-end">
            <LoadableButton
              id="completeFinish"
              isLoading={false}
              className="btn btn-lg  text-center text-uppercase-first-letter"
              onClick={() => {
                !resources?.lender.url
                  ? history.push(
                      `/finish?orchestrationToken=${orchestrationToken}`,
                      { status: "success" }
                    )
                  : onClick();
              }}
              disabled={false}
              variant={
                theme?.settings?.summary?.texts?.somethingWentWrongButton
                  ?.type === "link"
                  ? "link"
                  : "primary"
              }
            >
              <Trans i18nKey="common.continue">
                <b></b>
              </Trans>
            </LoadableButton>
          </ButtonsFooterContainer>
        </Footer>
      ) : null}
      {showProgressBar ? (
        <CustomProgressBar
          totalTimeInMinutes={5}
          hasProcessing={hasProcessing}
          onCompletedPercentages={(timeout) =>
            !timeout ? setShowProgressBar(false) : onCompletedPercentages()
          }
        />
      ) : null}
    </Content>
  );
}

export default WaitForSummaryData;
