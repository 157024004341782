import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
import { useTranslation, Trans } from "react-i18next";
import styled, { useTheme } from "styled-components/macro";
import { useHistory, useLocation } from "react-router";
import parse from "html-react-parser";
//contexts
import { SnackbarContext } from "../../context/snackbar";
import { SelectedBankContext } from "../../context/selectedBank";
import { ResourcesContext } from "../../context/consumer";
import { ConsentedBanksContext } from "../../context/consentedBanks";

//hooks
import { Bank, useBanksList } from "../../hooks/useBanksList";
import { useAddConsent } from "../../hooks/useAddConsent";
import { useConsumer } from "../../hooks/useConsumer";
import { Consent, useConsentedBanksList } from "../../hooks/useConsentedBanks";
import useMediaQuery from "../../hooks/useMediaQuery";

//layout components
import {
  ScreenContentWrapper,
  Footer as FooterContainer,
  ScreenContainer,
  Content,
} from "../layout/screens.layout";

//components
import TryAgain from "../../components/tryagain/tryagain.component";
import Progressbar from "../../components/progressbar/progressbar.component";
import {
  Input,
  InputContainer,
  Select,
} from "../../components/select/select.bank";
import { LoadableButton } from "../../components/button/loadable-button.component";
import Loading from "../../components/loading/loading.component";
import WaitForBankData from "./components/WaitForBankData";
//utils
import { scrollTop } from "../../utils/scrollTop";
import { useOrchestrationToken } from "../../hooks/useOrchestrationToken";

//hooks
import { useLender } from "../../hooks/useLender";
import { useLockConsents } from "../../hooks/useLockConsents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { LabelContainer } from "../../components/select/select.bank";
import { darken } from "polished";
import Navbar from "../../components/navbar/navbar.component";
import { ReactComponent as BuildingIcon } from "../../assets/building.svg";
import { ReactComponent as SuccessBankIcon } from "../../assets/successBankIcon.svg";
import { ReactComponent as FailedBankIcon } from "../../assets/failedBankIcon.svg";
import { getTextWithPopulatePlaceholders } from "../../utils/helpers";
import Modal from "../../components/modal/Modal";
import ExitButton from "../../components/select/exitButton";
import { useExitJourney } from "../../hooks/useExitJourney";
import { Header } from "../intro/components/IntroHeaderComponent";
import AssuranceItems from "../../components/assuranceSection/AssuranceItems";
import AddMissingBank from "./components/AddMissingBank";
import { useAddMissingBank } from "../../hooks/useAddMissingBank";
import useScreenSize from "../../hooks/useScreenSize";

const ProgressbarContainer = styled.div``;

const HeaderContainer = styled.div`
  font-size: 1.6rem;
  line-height: 2rem;
  margin-bottom: 12px;
  white-space: pre-line;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    margin-bottom: 0.5rem;
  }
  @media (max-width: 640px) {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
`;

const SelectContainer = styled.div`
  position: relative;
  margin-bottom: 20px;
  //   border: 1px solid #cfcfcf;
  border-radius: 8px;
  padding: 0 15px;
`;

const ErrorContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
`;

export const Button = styled(LoadableButton)`
  /* padding: 1rem 9rem !important; */
`;

const ButtonsFooterContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  justify-content: center;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;

  & .btn {
    padding: 0.8rem 3rem;
    margin-top: 0;
    max-width: calc(50% - 8px);
  }
`;

export const ModalWrapper = styled.div`
  position: fixed;
  z-index: 1001;
  top: 0px !important;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  padding: 10px;
  display: none;

  &.visible {
    display: block;
  }

  .modal-body {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 85%;
    max-width: 530px;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.7);
    padding: 2rem 2vw;
    h4 {
      font-size: 20px;
      width: 100%;
      text-align: center;
      margin: 5px auto 15px auto;
    }

    .btns-wrapper {
      display: flex;
      margin: 10px auto 0;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      button {
        margin: 0 5px;
      }
    }
  }

  .modal-header {
    display: flex;
    justify-content: center;

    .close-btn {
      cursor: pointer;
      position: absolute;
      right: 2rem;
      top: 2rem;
    }
  }
`;

const InteractionContainer = styled.div`
  border: 1px solid #cfcfcf;
  //   border-radius: 8px;
  margin-bottom: 20px;
  margin-top: 2rem;
`;

const DetailsSection = styled.div`
  font-size: 16px;
  line-height: 20px;
  display: flex;
  flex-direction: column;

  padding: 15px 5px 0;
  // span {
  //   color: ${(props) => props.theme.colors.text};
  // }
  .questions {
    display: flex;
    margin-top: 1rem;
    position: relative;

    @media (max-width: 425px) {
      flex-direction: column;
    }
    .btn {
      line-height: 20px;
      margin-left: auto;
      max-width: max-content;
      @media (max-width: 425px) {
        width: 100%;
      }
    }
  }

  .question-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    margin-bottom: 10px;
    > span {
      padding-right: 10px;
      text-align: justify;
    }
  }
  .bank-info {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .bank-logo {
    width: 42px;
    height: 42px;
    margin-bottom: 10px;
  }

  .bank-name {
    margin-bottom: 10px;
    font-weight: bold;
  }

  .btns-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .btn {
    line-height: 20px;
    margin-left: auto;
    max-width: max-content;
  }
`;

const StatusSection = styled.div`
  font-size: 16px;

  padding: 0 10px;
  flex-direction: column;
  display: flex;
  // span {
  //   color: ${(props) => props.theme.colors.text};
  // }
  .bank-country-name {
    position: absolute;
    right: 1rem;
    top: -1rem;
    font-style: italic;
  }
  div.d-flex {
    // border-bottom: 1px solid #888;
    width: 100%;

    p {
      display: flex;
      min-height: 40px;
      align-items: center;
      @media (max-width: 425px) {
        padding-left: 0px;
      }
    }
  }
  @media (max-width: 425px) {
    padding: 0px 15px;
  }
`;

const Panel = styled.div`
  border: ${(props) => `1px solid ${props.theme.colors.icons.default}`};
  border-radius: 20px;
  padding: 20px 10px;
  position: relative;
  .icon-wrapper {
    background: ${(props) => props.theme.colors.body};
    padding: 0;
  }
`;

const BankInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;

  .input-wrapper {
    min-width: 300px;
  }
`;
interface Props {
  shouldRedirect: boolean;
}

const SelectScreenV2: React.FC<Props> = ({ shouldRedirect = false }) => {
  const { type: consumerType } = useConsumer();
  const history = useHistory<any>();
  const [selectedBank, setSelectedBank] = useState<Bank | null>(null);
  const { openSnackbar } = useContext(SnackbarContext);
  const { setBank, bank } = useContext(SelectedBankContext);
  const [bankName, setBankName] = useState<string | null>(null);
  const { t, i18n } = useTranslation();
  const {
    bankName: missingBank,
    country,
    setBankName: setMissingBank,
    setCountry,
    addMissingBank,
  } = useAddMissingBank();
  const orchestrationToken = useOrchestrationToken();
  const [showZoomedImg, setShowZoomedImg] = useState(false);
  const [showBankIsNotListedModal, setShowBankIsNotListedModal] =
    useState(false);
  const isMobile = useMediaQuery("(max-width: 425px)");
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const screenSize = useScreenSize();
  const resume = query.get("resume");
  const { name: lenderName } = useLender();
  const { resources } = useContext(ResourcesContext);
  const { consentedBanks } = useContext(ConsentedBanksContext);
  const { loading: loadingConsentedBanksList } = useConsentedBanksList();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [errorModal, setErrorModal] = useState<boolean>(false);
  const [successModal, setSuccessModal] = useState<boolean>(false);

  const [showSelectPanel, setShowSelectPanel] = useState(false);
  const [showWaitForData, setWaitForData] = useState(true);
  const [shouldShowWaitForData, setShouldShowWaitForData] = useState(true);

  const [modalPrimaryBankIndicatorOpen, setModalPrimaryBankIndicatorOpen] =
    useState<boolean>(false);

  const [jointAccountIndicator, setJointAccountIndicator] = useState<
    boolean | null | undefined
  >(null);
  const [primaryBankIndicator, setPrimaryBankIndicator] = useState<
    boolean | null | undefined
  >(null);
  const [primaryYesClicked, setPrimaryYesClicked] = useState<boolean>(false);
  const [primaryNoClicked, setPrimaryNoClicked] = useState<boolean>(false);

  const modalRef = useRef(null);
  const modalRef2 = useRef(null);
  const modalRef3 = useRef(null);
  const modalRef4 = useRef(null);

  const theme: any = useTheme();

  console.log({ theme });

  const {
    loading,
    error,
    banks = [],
    refetch,
  } = useBanksList(resources?.processCompleted);
  const { execute: exitJourney, loading: exitJourneyLoading } =
    useExitJourney(orchestrationToken);
  const { url, singleConsentMode } = useLender();
  const [providers, setProviders] = useState<Bank[]>([]);

  useEffect(() => {
    setProviders(banks);
  }, [banks]);

  const lockConsents = useLockConsents(orchestrationToken);

  const {
    loading: loadingLockConsent,
    error: errorConsent,
    clearError: clearConsentError,
    execute,
  } = useAddConsent(orchestrationToken);

  useEffect(() => {
    if (error || errorConsent) {
      setSelectedBank(null);
      setBank(null);
      openSnackbar(true);
    }
  }, [error, errorConsent, openSnackbar, setBank, resources]);

  const myRef = useRef<null | HTMLDivElement>(null);

  const checkIfCanBeSelected = (
    selectedBank: Bank | null
  ): boolean | undefined => {
    const inConsents = consentedBanks.find(
      (c) => c.providerCode === selectedBank?.providerCode
    );
    return (
      ["Completed", "Processing"].includes(inConsents?.status || "") ||
      loadingLockConsent ||
      (selectedBank === null && !loadingLockConsent) ||
      !!error ||
      !!errorConsent ||
      (hasCompleted > 0 && singleConsentMode)
    );
  };

  const onSelect = (bank: Bank | null) => {
    console.log({ onSelect: bank });
    errorConsent && clearConsentError();
    if (!checkIfCanBeSelected(bank)) {
      setSelectedBank(bank);
      setBank(bank);
      execute(bank!.providerCode, null, null, (consentToken: string) => {
        scrollTop(() =>
          history.push(`/consent?orchestrationToken=${orchestrationToken}`, {
            consentToken,
          })
        );
      });
    }

    setTimeout(() => {
      myRef?.current?.scrollIntoView(true);
    }, 50);
  };
  const timeout = useRef<any>(null);

  const hasProcessing = useMemo(() => {
    return consentedBanks.filter((bank) => bank?.status === "Processing")
      .length;
  }, [consentedBanks]);
  const hasError = useMemo(() => {
    return consentedBanks.filter((bank) => bank?.status === "Error").length;
  }, [consentedBanks]);
  const hasCompleted = useMemo(() => {
    return consentedBanks.filter((bank) => bank?.status === "Completed").length;
  }, [consentedBanks]);

  useEffect(() => {
    if (
      !lockConsents.loading &&
      lockConsents &&
      hasCompleted > 0 &&
      singleConsentMode
    ) {
      lockConsents.execute(() => {
        document.body.style.overflow = "auto";
        setWaitForData(false);
        history.push(`/complete?orchestrationToken=${orchestrationToken}`);
      });
    }
  }, [
    lockConsents,
    history,
    hasCompleted,
    singleConsentMode,
    orchestrationToken,
  ]);
  const lastProcessedBank = useMemo(() => {
    if (consentedBanks.length) {
      return consentedBanks[0].status;
    }
  }, [consentedBanks]);
  useEffect(() => {
    if (!loadingConsentedBanksList) {
      if (consentedBanks.length === 0) {
        console.log({ consentedBanks });

        setShowSelectPanel(true);
      } else {
        shouldShowWaitForData &&
          lastProcessedBank === "Processing" &&
          setWaitForData(true);

        if (lastProcessedBank === "Error") {
          setTimeout(() => {
            setShowSelectPanel(true);
            setWaitForData(false);
          }, 1000);
        }

        if (!singleConsentMode) {
          if (
            lastProcessedBank === "Completed" ||
            lastProcessedBank === "Error"
          ) {
            setTimeout(() => {
              setShowSelectPanel(true);
              setWaitForData(false);
            }, 1000);
          }
        }
      }
    }
  }, [
    singleConsentMode,
    lastProcessedBank,
    consentedBanks,
    loadingConsentedBanksList,
  ]);

  useEffect(() => {
    if (hasProcessing) {
      timeout.current = window.setInterval(() => {
        refetch(true);
      }, 5000);
    }

    return () => clearInterval(timeout.current);
  }, [hasProcessing, refetch]);

  const completedBanksLength = useMemo(() => {
    return consentedBanks.filter((bank: any) => bank?.status === "Completed")
      .length;
  }, [consentedBanks]);

  useEffect(() => {
    lastProcessedBank === "Error" &&
      setTimeout(() => {
        setErrorModal(true);
      }, 1000);
  }, [lastProcessedBank, setErrorModal]);

  useEffect(() => {
    !singleConsentMode &&
      lastProcessedBank === "Completed" &&
      setTimeout(() => {
        setSuccessModal(true);
      }, 1000);
  }, [hasCompleted, setSuccessModal]);

  const addToLocalStorage = (id: string, status: string) => {
    window.sessionStorage.setItem(
      id,
      JSON.stringify({ status, disabled: true })
    );
  };

  const checkIfExistsInLocalStorage = (id: string, status: string): boolean => {
    if (window.sessionStorage.getItem(id)) {
    }
    const item =
      window.sessionStorage.getItem(id) !== null &&
      JSON.parse(window.sessionStorage.getItem(id) || "");

    if (item?.status === status && item?.disabled) {
      return false;
    }
    return true;
  };

  const checkIfErrorExists = (errorMsg: any) => {
    return i18n.exists(`errors.${errorMsg}`)
      ? `errors.${errorMsg}`
      : `errors.UnknownError`;
  };
  const renderHeader = useMemo(() => {
    if (!banks.length) return null;

    return (
      <HeaderContainer id="selectHeaderConsumer">
        {/* //NO BANKS */}
        {!consentedBanks.length && (
          <>
            {theme.settings.select?.searchForYourProviderText?.visible && (
              <Header>
                <span
                  className="with-custom-font"
                  style={{
                    ...theme.settings.select?.searchForYourProviderText?.styles,
                    marginBottom: "2rem",
                  }}
                >
                  {theme.settings?.select?.texts?.titleSelect
                    ? getTextWithPopulatePlaceholders(
                        theme.settings.select.texts.titleSelect,
                        resources
                      )
                    : t("selectScreen.search")}
                </span>
              </Header>
            )}
            {resume?.toLowerCase() === "true" && (
              <Trans i18nKey="selectScreen.welcomeBack">
                <p></p>
              </Trans>
            )}

            {theme.settings.select?.texts?.pleaseSelectProvider ? (
              getTextWithPopulatePlaceholders(
                theme.settings.select.texts.pleaseSelectProvider,
                resources
              )
            ) : (
              <Trans i18nKey="selectScreen.pleaseSelectProvider">
                <p className="test">
                  Please select the bank account provider you are giving access
                  to.
                </p>
              </Trans>
            )}

            {theme.settings.select?.texts?.titleCJconsumerNoBanks &&
              getTextWithPopulatePlaceholders(
                theme.settings.select.texts.titleCJconsumerNoBanks,
                resources
              )}
            {!theme.settings.select?.texts?.titleCJconsumerNoBanks && (
              <Trans i18nKey="selectScreen.titleCJconsumerNoBanks">
                <p className="test">
                  It's best to make sure you are providing access to the bank
                  accounts in which you receive income and use for payments.
                </p>
              </Trans>
            )}

            {!singleConsentMode &&
              theme.settings.select?.texts?.moreThanOneProvider &&
              getTextWithPopulatePlaceholders(
                theme.settings.select.texts.moreThanOneProvider,
                resources
              )}
            {!singleConsentMode &&
              !theme.settings.select?.texts?.moreThanOneProvider && (
                <Trans i18nKey="selectScreen.moreThanOneProvider">
                  <p className="test">
                    If you use more than one provider, you`ll be able to add
                    them later.
                  </p>
                </Trans>
              )}
          </>
        )}

        {/* //IF COMPLETED OR Processing*/}

        {(lastProcessedBank === "Completed" ||
          lastProcessedBank === "Processing") && (
          <>
            <div className="d-flex align-items-center my-3">
              <SuccessBankIcon
                className="mr-3"
                style={{ minWidth: "2.7rem" }}
              />
              <p>
                {t(
                  singleConsentMode
                    ? "selectScreen.titleCJconsumerHasBanks_with_singleConsentMode"
                    : "selectScreen.titleCJconsumerHasBanks",
                  { bankName }
                )}
              </p>
            </div>
          </>
        )}

        {lastProcessedBank === "Error" && (
          //IF FAIL
          <div className="d-flex align-items-center my-3">
            <FailedBankIcon className="mr-3" style={{ minWidth: "2.7rem" }} />
            <p className="mb-0">
              {t("selectScreen.titleCJconsumerHasBanks_FailBank.first", {
                bankName,
              })}
              <br />

              {t(
                checkIfErrorExists(
                  consentedBanks.filter((bank) => bank?.status === "Error")[0]
                    ?.errorMessage
                )
              )}
            </p>
          </div>
          //
        )}
      </HeaderContainer>
    );
  }, [banks, completedBanksLength, bankName]);

  const isCompletedOrProcessing = (selectedBank: Bank | null) => {
    let status = consentedBanks.find(
      (bank: Consent) => bank.providerCode === selectedBank?.providerCode
    )?.status;

    let completed =
      !!selectedBank && ["Completed", "Processing"].includes(status || "");

    return completed;
  };

  const completedBank = (selectedBank: Bank | null) => {
    let status = consentedBanks.find(
      (bank: Consent) => bank.providerCode === selectedBank?.providerCode
    )?.status;
    if (status === "Completed") return true;

    return false;
  };
  useEffect(() => {
    if (selectedBank) {
      const selectedBankInConsents = consentedBanks.find(
        (consentedBank) => consentedBank?.providerCode === bank?.providerCode
      );
      if (selectedBankInConsents) {
        setJointAccountIndicator(selectedBankInConsents?.jointAccountIndicator);
        setPrimaryBankIndicator(selectedBankInConsents?.primaryBankIndicator);
      } else {
        setJointAccountIndicator(null);
        setPrimaryBankIndicator(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBank]);

  useEffect(() => {
    consentedBanks.length && setBankName(consentedBanks[0].name);
  }, [consentedBanks]);

  useEffect(() => {
    if (shouldRedirect) {
      window.location.href = url;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCompletedPercentages = () => {
    setShouldShowWaitForData(false);
    !singleConsentMode && setWaitForData(false);
    !singleConsentMode && setShowSelectPanel(true);
  };
  const getMessageByStatus = (selectedBankInConsents: any, errorMsg: any) => {
    if (selectedBankInConsents?.status === "Processing") {
      return "selectScreen.bankProcessing";
    }
    if (selectedBankInConsents?.status === "Error") {
      return checkIfErrorExists(errorMsg);
    }
    return "selectScreen.bankAdded";
  };

  const getStyleByStatus = (selectedBankInConsents: { status: string }) => {
    if (selectedBankInConsents?.status === "Processing") {
      return darken(0.5, theme?.colors.banks.processingBankBackground);
    }
    if (selectedBankInConsents?.status === "Error") {
      return darken(0.5, theme?.colors.banks.failedBankBackground);
    }
    return darken(0.5, theme?.colors.banks.successBankBackground);
  };

  return loadingConsentedBanksList ? (
    <Loading />
  ) : (
    <ScreenContentWrapper
      $currentScreenWidth={screenSize.width}
      style={{
        ...theme.settings.screenContainer,
        minHeight: "auto",
        minWidth: !showSelectPanel ? "50vw" : null,
      }}
    >
      {theme?.settings.customBackground ? (
        <div
          className="custom-bg"
          style={theme.settings.customBackground?.styles}
        >
          {" "}
        </div>
      ) : null}
      <div className="w-100">
        <ScreenContainer style={{ minHeight: "auto" }}>
          <Navbar />
          <ProgressbarContainer>
            <Progressbar isManage={consentedBanks.length > 0} />
          </ProgressbarContainer>
          {consentedBanks.length > 0 && showWaitForData ? (
            <WaitForBankData
              hasProcessing={hasProcessing}
              lastProcessedBankStatus={lastProcessedBank}
              onCompletedPercentages={onCompletedPercentages}
            />
          ) : null}

          {showSelectPanel && (
            <>
              <Content className="mt-4">
                <div className="row mx-0">
                  <div className="col-12 col-md-5">{renderHeader}</div>

                  <Panel className="col-12 col-md-7 mt-3 pb-0">
                    <div
                      style={{
                        position: "absolute",
                        top: "-25px",
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        left: 0,
                      }}
                    >
                      <div className="icon-wrapper">
                        <BuildingIcon style={{ width: "40px" }} />
                      </div>
                    </div>
                    <SelectContainer>
                      <span ref={myRef}></span>
                      <Select
                        id="selectBank"
                        value={selectedBank}
                        consents={consentedBanks}
                        singleConsentMode={singleConsentMode as boolean}
                        options={providers.length > 0 ? providers : banks}
                        placeholder={""}
                        onGroupSelected={(groupCode) => {
                          setProviders(
                            banks.filter((bank) => bank.groupCode === groupCode)
                          );
                        }}
                        onGroupUnselected={() => {
                          setProviders([]);
                        }}
                        fieldPlaceholder={
                          theme.settings?.select?.texts?.selectBankInputDefault
                            ? getTextWithPopulatePlaceholders(
                                theme.settings.select.texts
                                  .selectBankInputDefault,
                                resources
                              )
                            : t("selectScreen.type")
                        }
                        onSelect={onSelect}
                        isLoading={hasProcessing ? false : loading}
                        onSelectLoading={loadingLockConsent}
                      />
                      {hasCompleted == 0 && (
                        <Content className="my-0 w-100">
                          <p>
                            <span
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                                ...theme?.settings?.select?.texts
                                  ?.myBankIsNotListedButton?.styles,
                              }}
                              onClick={() => {
                                setShowBankIsNotListedModal(true);
                                const ref: any = modalRef3;
                                if (ref?.current) {
                                  ref.current.style.top = `${
                                    (window.pageYOffset ||
                                      document.documentElement.scrollTop ||
                                      0) - 76
                                  }px`;
                                }
                                document.body.style.overflow = "hidden";
                              }}
                            >
                              {theme.settings?.select?.texts?.bankNotListedLink
                                ? getTextWithPopulatePlaceholders(
                                    theme.settings.select.texts
                                      .bankNotListedLink,
                                    resources
                                  )
                                : t("selectScreen.bankIsNotListedLink")}
                            </span>
                          </p>
                        </Content>
                      )}
                      {error && (
                        <ErrorContainer>
                          <TryAgain refetch={() => refetch(false)} />
                        </ErrorContainer>
                      )}
                      {/* {selectedBank && (
                      <InteractionContainer>
                        <>
                          <DetailsSection>
                            <div className="bank-info">
                              <img
                                className="bank-logo"
                                src={selectedBank.logo}
                              />
                              <span className="bank-name">
                                {selectedBank?.name}
                              </span>
                            </div>
                          </DetailsSection>
                          <StatusSection>
                            <LabelContainer>
                              <span className="rotated">
                                {t("selectScreen.status")}
                              </span>
                            </LabelContainer>
                            <div className="d-flex position-relative">
                              <p>
                                {(() => {
                                  const selectedBankInConsents =
                                    consentedBanks.find(
                                      (consentedBank) =>
                                        consentedBank?.providerCode ===
                                        bank?.providerCode
                                    );
                                  if (selectedBankInConsents) {
                                    const bankFromProviders = banks.find(
                                      (b) =>
                                        b.providerCode ===
                                        selectedBankInConsents.providerCode
                                    );
                                    if (bankFromProviders) {
                                      const errorMsg =
                                        selectedBankInConsents?.errorMessage ||
                                        null;
                                      return (
                                        <span
                                          style={{
                                            color: getStyleByStatus(
                                              selectedBankInConsents
                                            ),
                                          }}
                                          className="mt-4"
                                        >
                                          <Trans
                                            i18nKey={getMessageByStatus(
                                              selectedBankInConsents,
                                              errorMsg
                                            )}
                                            tOptions={{
                                              bank: bankFromProviders.name,
                                            }}
                                          >
                                            <b></b>
                                          </Trans>
                                        </span>
                                      );
                                    }
                                  }

                                  return (
                                    <span
                                      className="mt-2"
                                      style={{
                                        color: darken(
                                          0.5,
                                          theme?.colors.banks
                                            .processingBankBackground
                                        ),
                                      }}
                                    >
                                      {t("selectScreen.bankHasNotBeenAdded")}
                                    </span>
                                  );
                                })()}
                              </p>
                              <span className="bank-country-name">
                                {t(
                                  `selectScreen.countryCodes.${selectedBank?.countryCode}`
                                )}
                              </span>
                            </div>
                          </StatusSection>

                          <div
                            style={{
                              padding: "0 10px 15px",
                            }}
                          >
                            <Button
                              id="selectContinue"
                              onClick={() => {
                                execute(
                                  selectedBank!.providerCode,
                                  jointAccountIndicator,
                                  primaryBankIndicator,
                                  (consentToken: string) => {
                                    scrollTop(() =>
                                      history.push(
                                        `/consent?orchestrationToken=${orchestrationToken}`,
                                        {
                                          consentToken,
                                        }
                                      )
                                    );
                                  }
                                );
                              }}
                              isLoading={
                                loadingLockConsent && !singleConsentMode
                              }
                              className="btn exp-default  text-uppercase-first-letter text-center  btn-block"
                              disabled={(() => {
                                const inConsents = consentedBanks.find(
                                  (c) =>
                                    c.providerCode ===
                                    selectedBank?.providerCode
                                );
                                return (
                                  ["Completed", "Processing"].includes(
                                    inConsents?.status || ""
                                  ) ||
                                  loadingLockConsent ||
                                  (selectedBank === null &&
                                    !loadingLockConsent) ||
                                  !!error ||
                                  !!errorConsent ||
                                  (hasCompleted > 0 && singleConsentMode)
                                );
                              })()}
                            >
                              {(() => {
                                const inConsents = consentedBanks.find(
                                  (c) =>
                                    c.providerCode ===
                                    selectedBank?.providerCode
                                );

                                if (inConsents?.status === "Error") {
                                  return t("common.retry");
                                } else {
                                  let bankName =
                                    consentedBanks.find(
                                      (c) =>
                                        c.providerCode ===
                                        selectedBank?.providerCode
                                    )?.status !== "Error" && selectedBank?.name;
                                  if (
                                    !theme.settings.select?.texts
                                      ?.proceedBankButton
                                  )
                                    return (
                                      t("common.connectTo") + " " + bankName
                                    );

                                  return getTextWithPopulatePlaceholders(
                                    theme.settings.select.texts
                                      .proceedBankButton,
                                    resources,
                                    theme.settings.select.texts
                                      .proceedBankButton.withBankName
                                      ? bankName
                                      : null
                                  );
                                }
                              })()}
                            </Button>
                          </div>
                        </>
                      </InteractionContainer>
                    )} */}
                    </SelectContainer>
                  </Panel>
                </div>
              </Content>
              {!singleConsentMode && (
                <FooterContainer
                  style={{
                    flexDirection: "column",
                    minHeight: "64px",
                    height: "auto",
                  }}
                >
                  <ButtonsFooterContainer>
                    <Button
                      id="selectFinish"
                      onClick={() => {
                        if (!singleConsentMode) {
                          setModalOpen(true);
                        } else {
                          lockConsents.execute(() => {
                            document.body.style.overflow = "auto";
                            history.push(
                              `/complete?orchestrationToken=${orchestrationToken}`
                            );
                          });
                        }
                        const ref: any = modalRef2;
                        if (ref?.current) {
                          ref.current.style.top = `${
                            (window.pageYOffset ||
                              document.documentElement.scrollTop ||
                              0) - 76
                          }px`;
                        }
                        document.body.style.overflow = "hidden";
                      }}
                      isLoading={lockConsents.loading}
                      disabled={
                        hasProcessing ? false : loading || !completedBanksLength
                      }
                      variant={
                        theme.settings?.select?.texts?.selectContinueButton
                          ?.type === "link"
                          ? "link"
                          : "primary"
                      }
                      className="btn text-uppercase-first-letter text-center  btn-block"
                    >
                      {theme.settings?.select?.texts?.selectContinueButton
                        ? getTextWithPopulatePlaceholders(
                            theme.settings.select.texts.selectContinueButton,
                            resources
                          )
                        : t("common.continue")}
                    </Button>
                  </ButtonsFooterContainer>
                </FooterContainer>
              )}
            </>
          )}

          {loading || hasProcessing || hasCompleted
            ? null
            : (!theme.settings?.select?.texts?.selectExitButton ||
                theme.settings?.select?.texts?.selectExitButton?.visible) && (
                <ExitButton
                  lenderUrl={resources?.lender?.url}
                  hasFailedBank={hasError}
                />
              )}

          {theme.settings?.assuranceSection?.visible && (
            <Content className="mt-0">
              <AssuranceItems />
            </Content>
          )}
        </ScreenContainer>
      </div>
      <Modal>
        <ModalWrapper
          className={
            errorModal &&
            checkIfExistsInLocalStorage(
              consentedBanks[0].id,
              consentedBanks[0].status
            )
              ? "visible"
              : ""
          }
          ref={modalRef3}
        >
          <div className="modal-body">
            <div className="modal-header">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M38.13 30.55L23.7 5.57005C22.43 3.38005 20.84 3.05005 20 3.05005C19.16 3.05005 17.57 3.38005 16.31 5.57005L1.87001 30.57C1.41678 31.2231 1.1455 31.9851 1.08399 32.7776C1.02248 33.5702 1.17297 34.3649 1.52001 35.0801C1.92738 35.7286 2.50665 36.2514 3.1934 36.5903C3.88016 36.9293 4.64747 37.0711 5.41001 37H34.59C35.3496 37.0742 36.1149 36.9369 36.8014 36.6034C37.4879 36.2698 38.0688 35.753 38.48 35.1101C38.8366 34.3886 38.9923 33.5844 38.9307 32.782C38.8691 31.9796 38.5925 31.2086 38.13 30.55ZM36.76 34.0601C36.5073 34.3847 36.174 34.6376 35.7934 34.7937C35.4128 34.9498 34.9979 35.0037 34.59 34.9501H5.41001C5.00225 35.0017 4.58805 34.9469 4.20777 34.7909C3.82749 34.6349 3.49408 34.3832 3.24001 34.0601C3.08454 33.6451 3.03602 33.1976 3.09894 32.759C3.16185 32.3203 3.33417 31.9046 3.60001 31.55L18 6.57005C18.1738 6.16561 18.4494 5.81305 18.7999 5.54669C19.1503 5.28032 19.5638 5.10923 20 5.05005C20.4362 5.10923 20.8497 5.28032 21.2002 5.54669C21.5506 5.81305 21.8262 6.16561 22 6.57005L36.43 31.57C36.6875 31.925 36.8525 32.3386 36.9101 32.7733C36.9677 33.208 36.9161 33.6503 36.76 34.0601Z"
                  fill="#A3B6D2"
                />
                <path
                  d="M16.47 14.5L17.47 16.22C17.47 16.22 20.86 14.28 22.47 16.48C23.56 17.92 22.4 19.55 20.78 21.53C20.2916 22.0788 19.8595 22.6751 19.49 23.31C19.0648 24.3444 18.8967 25.4664 19 26.58V27.14H21V26.46C20.9329 25.7003 21.0354 24.9352 21.3 24.22C21.6101 23.7128 21.9649 23.2342 22.36 22.79C23.89 20.92 26.21 18.09 24.1 15.28C21.99 12.47 18.16 13.5 16.47 14.5Z"
                  fill="#18488E"
                />
                <path
                  d="M20.08 32.49C20.9637 32.49 21.68 31.7737 21.68 30.89C21.68 30.0064 20.9637 29.29 20.08 29.29C19.1964 29.29 18.48 30.0064 18.48 30.89C18.48 31.7737 19.1964 32.49 20.08 32.49Z"
                  fill="#18488E"
                />
              </svg>

              <span
                className="close-btn"
                onClick={() => {
                  setErrorModal(false);
                  addToLocalStorage(
                    consentedBanks[0].id,
                    consentedBanks[0].status
                  );
                  document.body.style.overflow = "auto";
                }}
              >
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  size="lg"
                  color={
                    theme?.colors?.icons?.default ??
                    "var(--exp-dark-grey) !important"
                  }
                />
              </span>
            </div>
            <div>
              <h4>
                <span className="modal-title">
                  {t("selectScreen.titleCJconsumerHasBanks_FailBank.first", {
                    bankName: (() => {
                      return consentedBanks.filter(
                        (bank) => bank?.status === "Error"
                      )[0]?.name;
                    })(),
                  })}
                  <br />
                  {t(
                    checkIfErrorExists(
                      consentedBanks.filter(
                        (bank) => bank?.status === "Error"
                      )[0]?.errorMessage
                    )
                  )}
                </span>

                <br />
                <br />
                <span className="modal-subtitle">
                  {t("selectScreen.titleCJconsumerHasBanks_FailBank.second")}
                </span>
              </h4>
            </div>
            <div className="btns-wrapper">
              <Button
                id="cancelBtn"
                disabled={false}
                isLoading={loadingLockConsent}
                onClick={() => {
                  const errorBankProviderCode = consentedBanks.filter(
                    (bank) => bank?.status === "Error"
                  )[0].providerCode;
                  const bank = banks.filter(
                    (bank) => bank.providerCode === errorBankProviderCode
                  )[0];
                  setBank(bank);
                  addToLocalStorage(
                    consentedBanks[0].id,
                    consentedBanks[0].status
                  );
                  execute(
                    errorBankProviderCode,
                    jointAccountIndicator,
                    primaryBankIndicator,
                    (consentToken: string) => {
                      scrollTop(() =>
                        history.push(
                          `/consent?orchestrationToken=${orchestrationToken}`,
                          {
                            consentToken,
                          }
                        )
                      );
                    }
                  );
                }}
                variant={
                  theme.settings?.select?.texts?.failedAddedBankModal
                    ?.retryButton?.type === "link"
                    ? "link"
                    : "primary"
                }
                className="btn text-uppercase-first-letter text-center bg-white btn-block"
              >
                {theme.settings?.select?.texts?.failedAddedBankModal
                  ?.retryButton
                  ? getTextWithPopulatePlaceholders(
                      theme.settings.select.texts.failedAddedBankModal
                        .retryButton,
                      resources
                    )
                  : t("common.retry")}
              </Button>
              <Button
                id="continueOrExitBtn"
                isLoading={lockConsents.loading}
                disabled={loadingLockConsent}
                onClick={() => {
                  setErrorModal(false);
                  addToLocalStorage(
                    consentedBanks[0].id,
                    consentedBanks[0].status
                  );
                  document.body.style.overflow = "auto";
                }}
                variant={
                  theme.settings?.select?.texts?.failedAddedBankModal
                    ?.tryAnotherBankButton?.type === "link"
                    ? "link"
                    : "primary"
                }
                className="btn text-uppercase-first-letter text-center bg-white btn-block"
              >
                {" "}
                {theme.settings?.select?.texts?.failedAddedBankModal
                  ?.tryAnotherBankButton
                  ? getTextWithPopulatePlaceholders(
                      theme.settings.select.texts.failedAddedBankModal
                        .tryAnotherBankButton,
                      resources
                    )
                  : t("selectScreen.tryAnotherBank")}
              </Button>
            </div>
          </div>
        </ModalWrapper>
        <ModalWrapper
          className={
            !singleConsentMode &&
            successModal &&
            checkIfExistsInLocalStorage(
              consentedBanks[0].id,
              consentedBanks[0].status
            )
              ? "visible"
              : ""
          }
          ref={modalRef3}
        >
          <div className="modal-body">
            <div className="modal-header">
              <svg
                width="28"
                height="29"
                viewBox="0 0 28 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M14 0.375C6.25001 0.375 1.18017e-05 6.625 0.125012 14.375C0.250012 22.125 6.37501 28.375 14.125 28.25C21.75 28.25 28 22.125 28 14.5C28.125 6.75 21.875 0.5 14.25 0.375C14.125 0.5 14 0.375 14 0.375ZM14 26C7.62501 26 2.37501 20.75 2.37501 14.375C2.37501 8 7.62501 2.75 14 2.75C20.375 2.75 25.625 8 25.625 14.375C25.625 20.75 20.5 26 14 26Z" />
                <path d="M8.00001 14.5C8.25001 14.5 8.50001 14.625 8.75001 14.875C9.50001 15.625 10.375 16.5 11.125 17.25C11.25 17.375 11.375 17.375 11.625 17.25L19.375 9.5C19.625 9.25 20 9.125 20.375 9.25C20.75 9.375 21 9.62499 21 9.99999C21 10.25 21 10.625 20.75 10.875L16 15.625L12.25 19.375C11.875 19.75 11.375 19.875 10.875 19.625C10.75 19.625 10.75 19.5 10.625 19.375L7.50001 16.25C7.12501 15.875 7.00001 15.375 7.37501 14.875C7.50001 14.5 7.75001 14.375 8.00001 14.5Z" />
              </svg>

              <span
                className="close-btn"
                onClick={() => {
                  setSuccessModal(false);
                  addToLocalStorage(
                    consentedBanks[0].id,
                    consentedBanks[0].status
                  );
                  document.body.style.overflow = "auto";
                }}
              >
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  size="lg"
                  color={
                    theme?.colors?.icons?.default ??
                    "var(--exp-dark-grey) !important"
                  }
                />
              </span>
            </div>
            <div className="d-flex flex-column" style={{ margin: "auto" }}>
              <h4 style={{ whiteSpace: "pre-line", lineHeight: "1.5rem" }}>
                <span className="modal-title">
                  {t("selectScreen.bankAdded.title", {
                    bank: consentedBanks?.[0]?.name,
                  })}
                </span>
                <br />
                <br />
                <span className="modal-subtitle">
                  {t("selectScreen.bankAdded.subTitle")}
                </span>
              </h4>
            </div>
            <div className="btns-wrapper">
              <Button
                id="cancelBtn"
                disabled={false}
                isLoading={false}
                onClick={() => {
                  setSuccessModal(false);
                  addToLocalStorage(
                    consentedBanks[0].id,
                    consentedBanks[0].status
                  );
                  document.body.style.overflow = "auto";
                }}
                variant={
                  theme.settings?.select?.texts?.successAddedBankModal
                    ?.addAnotherBankButon?.type == "link"
                    ? "link"
                    : "primary"
                }
                className="btn text-uppercase-first-letter text-center bg-white btn-block"
              >
                {theme.settings?.select?.texts?.successAddedBankModal
                  ?.addAnotherBankButon
                  ? getTextWithPopulatePlaceholders(
                      theme.settings.select.texts.successAddedBankModal
                        .addAnotherBankButon,
                      resources
                    )
                  : t("selectScreen.addBank")}
              </Button>
              <Button
                id="continueOrExitBtn"
                isLoading={lockConsents.loading}
                disabled={lockConsents.loading}
                onClick={() => {
                  lockConsents.execute(() => {
                    document.body.style.overflow = "auto";
                    history.push(
                      `/complete?orchestrationToken=${orchestrationToken}`
                    );
                  });
                }}
                variant={
                  theme.settings?.select?.texts?.successAddedBankModal
                    ?.finishButon?.type == "link"
                    ? "link"
                    : "primary"
                }
                className="btn text-uppercase-first-letter text-center bg-white btn-block"
              >
                {theme.settings?.select?.texts?.successAddedBankModal
                  ?.finishButon
                  ? getTextWithPopulatePlaceholders(
                      theme.settings.select.texts.successAddedBankModal
                        .finishButon,
                      resources
                    )
                  : t("common.finish")}
              </Button>
            </div>
          </div>
        </ModalWrapper>
        <ModalWrapper
          className={showBankIsNotListedModal ? "visible" : ""}
          ref={modalRef3}
        >
          <div className="modal-body">
            <div className="modal-header">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M38.13 30.55L23.7 5.56999C22.43 3.37999 20.84 3.04999 20 3.04999C19.16 3.04999 17.57 3.37999 16.31 5.56999L1.87001 30.57C1.41678 31.2231 1.1455 31.985 1.08399 32.7776C1.02248 33.5701 1.17297 34.3648 1.52001 35.08C1.92738 35.7285 2.50665 36.2513 3.1934 36.5903C3.88016 36.9292 4.64747 37.0711 5.41001 37H34.59C35.3496 37.0741 36.1149 36.9369 36.8014 36.6033C37.4879 36.2698 38.0688 35.753 38.48 35.11C38.8366 34.3885 38.9923 33.5844 38.9307 32.782C38.8691 31.9796 38.5925 31.2086 38.13 30.55ZM36.76 34.06C36.5073 34.3846 36.174 34.6375 35.7934 34.7936C35.4128 34.9498 34.9979 35.0037 34.59 34.95H5.41001C5.00225 35.0016 4.58805 34.9468 4.20777 34.7909C3.82749 34.6349 3.49408 34.3831 3.24001 34.06C3.08454 33.645 3.03602 33.1976 3.09894 32.7589C3.16185 32.3203 3.33417 31.9045 3.60001 31.55L18 6.56999C18.1738 6.16555 18.4494 5.81299 18.7999 5.54663C19.1503 5.28026 19.5638 5.10917 20 5.04999C20.4362 5.10917 20.8497 5.28026 21.2002 5.54663C21.5506 5.81299 21.8262 6.16555 22 6.56999L36.43 31.57C36.6875 31.925 36.8525 32.3385 36.9101 32.7732C36.9677 33.208 36.9161 33.6502 36.76 34.06Z" />
                <path d="M16.47 14.5L17.47 16.22C17.47 16.22 20.86 14.28 22.47 16.48C23.56 17.92 22.4 19.55 20.78 21.53C20.2916 22.0788 19.8595 22.6751 19.49 23.31C19.0648 24.3444 18.8967 25.4664 19 26.58V27.14H21V26.46C20.9329 25.7003 21.0354 24.9352 21.3 24.22C21.6101 23.7128 21.9649 23.2342 22.36 22.79C23.89 20.92 26.21 18.09 24.1 15.28C21.99 12.47 18.16 13.5 16.47 14.5Z" />
                <path d="M20.08 32.49C20.9637 32.49 21.68 31.7737 21.68 30.89C21.68 30.0064 20.9637 29.29 20.08 29.29C19.1964 29.29 18.48 30.0064 18.48 30.89C18.48 31.7737 19.1964 32.49 20.08 32.49Z" />
              </svg>

              <span
                className="close-btn"
                onClick={() => {
                  setShowBankIsNotListedModal(false);
                  document.body.style.overflow = "auto";
                }}
              >
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  size="lg"
                  color={
                    theme?.colors?.icons?.default ??
                    "var(--exp-dark-grey) !important"
                  }
                />
              </span>
            </div>
            <div>
              <h4 style={{ whiteSpace: "pre-line", lineHeight: "1.5rem" }}>
                <span className="modal-title">
                  {theme.settings?.select?.texts?.bankNotListedTitle
                    ? getTextWithPopulatePlaceholders(
                        theme.settings.select.texts.bankNotListedTitle,
                        resources
                      )
                    : t("selectScreen.bankIsNotListedTitle")}
                </span>
                <br />
                <br />
                <span className="modal-subtitle">
                  {theme.settings?.select?.texts?.bankNotListedQuestion
                    ? getTextWithPopulatePlaceholders(
                        theme.settings.select.texts.bankNotListedQuestion,
                        resources
                      )
                    : t("selectScreen.bankIsNotListedHeader")}
                </span>
              </h4>
            </div>
            <AddMissingBank
              country={country}
              setBankName={setMissingBank}
              setCountry={setCountry}
            />
            <div className="btns-wrapper">
              <Button
                id="continueBtn"
                isLoading={exitJourneyLoading}
                disabled={exitJourneyLoading}
                onClick={() => {
                  missingBank && addMissingBank();
                  setShowBankIsNotListedModal(false);
                  document.body.style.overflow = "auto";
                }}
                variant={
                  theme.settings.select?.texts
                    ?.selectTryAnotherBankContinueButton?.type === "link"
                    ? "link"
                    : "secondary"
                }
                className="btn text-uppercase-first-letter text-center bg-white btn-block"
              >
                {theme.settings?.select?.texts
                  ?.selectTryAnotherBankContinueButton ? (
                  getTextWithPopulatePlaceholders(
                    theme.settings.select.texts
                      .selectTryAnotherBankContinueButton,
                    resources
                  )
                ) : (
                  <Trans
                    i18nKey="common.continue"
                    tOptions={{
                      lenderName,
                    }}
                  >
                    <b></b>
                  </Trans>
                )}
              </Button>

              <Button
                id="ExitBtn"
                isLoading={exitJourneyLoading}
                disabled={exitJourneyLoading}
                onClick={() => {
                  missingBank && addMissingBank();
                  !resources?.lender.url
                    ? exitJourney(
                        () =>
                          history.push(
                            `/finish?orchestrationToken=${orchestrationToken}`,
                            { status: "fail" }
                          ),
                        "BankProviderNotFound",
                        "ProcessingFailed"
                      )
                    : exitJourney(
                        () => window.open(resources?.lender.url, "_self"),
                        "BankProviderNotFound",
                        "ProcessingFailed"
                      );
                }}
                variant={
                  theme.settings.select?.texts?.selectTryAnotherBankExitButton
                    ?.type === "link"
                    ? "link"
                    : "primary"
                }
                className="btn text-uppercase-first-letter text-center bg-white btn-block"
              >
                {theme.settings?.select?.texts
                  ?.selectTryAnotherBankExitButton ? (
                  getTextWithPopulatePlaceholders(
                    theme.settings.select.texts.selectTryAnotherBankExitButton,
                    resources
                  )
                ) : (
                  <Trans
                    i18nKey="selectScreen.submitAndExit"
                    tOptions={{
                      lenderName,
                    }}
                  >
                    <b></b>
                  </Trans>
                )}
              </Button>
            </div>
          </div>
        </ModalWrapper>
        <ModalWrapper className={modalOpen ? "visible" : ""} ref={modalRef2}>
          <div className="modal-body">
            <div className="modal-header">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M23.3 30.73C23.0963 30.7376 22.8951 30.6828 22.7234 30.5729C22.5518 30.4629 22.4178 30.3032 22.3395 30.115C22.2611 29.9268 22.2422 29.7191 22.2852 29.5198C22.3282 29.3206 22.431 29.1392 22.58 29L31.47 20.11L22.38 11C22.2832 10.9067 22.2062 10.7949 22.1537 10.6712C22.1011 10.5475 22.074 10.4144 22.074 10.28C22.074 10.1456 22.1011 10.0126 22.1537 9.88886C22.2062 9.76515 22.2832 9.65331 22.38 9.56003C22.4734 9.46169 22.5859 9.38339 22.7105 9.32988C22.8351 9.27637 22.9694 9.24878 23.105 9.24878C23.2406 9.24878 23.3749 9.27637 23.4995 9.32988C23.6241 9.38339 23.7366 9.46169 23.83 9.56003L33.64 19.37C33.7368 19.4633 33.8138 19.5752 33.8663 19.6989C33.9189 19.8226 33.946 19.9556 33.946 20.09C33.946 20.2245 33.9189 20.3575 33.8663 20.4812C33.8138 20.6049 33.7368 20.7167 33.64 20.81L24 30.43C23.8154 30.6184 23.5638 30.7263 23.3 30.73Z" />
                <path d="M15.3 30.73C15.0954 30.7398 14.8928 30.6866 14.7195 30.5774C14.5461 30.4683 14.4105 30.3086 14.3309 30.1199C14.2513 29.9313 14.2315 29.7227 14.2743 29.5224C14.317 29.3221 14.4202 29.1398 14.57 29L23.46 20.11L14.37 11C14.2732 10.9067 14.1962 10.7949 14.1436 10.6712C14.0911 10.5475 14.064 10.4144 14.064 10.28C14.064 10.1456 14.0911 10.0126 14.1436 9.88886C14.1962 9.76515 14.2732 9.65331 14.37 9.56003C14.4634 9.46169 14.5759 9.38339 14.7005 9.32988C14.8251 9.27637 14.9594 9.24878 15.095 9.24878C15.2306 9.24878 15.3648 9.27637 15.4895 9.32988C15.6141 9.38339 15.7266 9.46169 15.82 9.56003L25.63 19.37C25.7268 19.4633 25.8038 19.5752 25.8563 19.6989C25.9089 19.8226 25.936 19.9556 25.936 20.09C25.936 20.2245 25.9089 20.3575 25.8563 20.4812C25.8038 20.6049 25.7268 20.7167 25.63 20.81L16 30.43C15.8154 30.6184 15.5638 30.7263 15.3 30.73Z" />
                <path d="M7.29 30.73C7.08543 30.7398 6.88279 30.6865 6.70947 30.5774C6.53615 30.4683 6.40051 30.3086 6.32089 30.1199C6.24127 29.9312 6.2215 29.7226 6.26427 29.5223C6.30703 29.322 6.41026 29.1397 6.56 29L15.45 20.11L6.36 11C6.17037 10.8077 6.06489 10.548 6.06677 10.2779C6.06864 10.0079 6.17772 9.74961 6.37 9.55998C6.56228 9.37035 6.82202 9.26487 7.09207 9.26675C7.36212 9.26862 7.62037 9.3777 7.81 9.56998L17.62 19.38C17.7168 19.4733 17.7938 19.5851 17.8463 19.7088C17.8989 19.8325 17.926 19.9656 17.926 20.1C17.926 20.2344 17.8989 20.3674 17.8463 20.4912C17.7938 20.6149 17.7168 20.7267 17.62 20.82L8 30.43C7.90739 30.5245 7.79694 30.5996 7.67507 30.6511C7.55319 30.7026 7.42231 30.7294 7.29 30.73Z" />
              </svg>

              <span
                className="close-btn"
                onClick={() => {
                  setModalOpen(false);
                  document.body.style.overflow = "auto";
                }}
              >
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  size="lg"
                  color={
                    theme?.colors?.icons?.default ??
                    "var(--exp-dark-grey) !important"
                  }
                />
              </span>
            </div>
            <div>
              <h4 className="modal-title">{t("selectScreen.modalQuestion")}</h4>
            </div>
            <div className="btns-wrapper">
              <Button
                id="finishYes"
                disabled={lockConsents.loading}
                isLoading={lockConsents.loading}
                onClick={() =>
                  lockConsents.execute(() => {
                    document.body.style.overflow = "auto";
                    history.push(
                      `/complete?orchestrationToken=${orchestrationToken}`
                    );
                  })
                }
                variant={
                  theme.settings?.select?.texts?.selectYesFinishButton?.type ===
                  "link"
                    ? "link"
                    : "primary"
                }
                className="btn text-uppercase-first-letter text-center bg-white btn-block"
              >
                {theme.settings?.select?.texts?.selectYesFinishButton
                  ? getTextWithPopulatePlaceholders(
                      theme.settings.select.texts.selectYesFinishButton,
                      resources
                    )
                  : t("common.yes")}
              </Button>
              <Button
                id="finishNo"
                isLoading={false}
                disabled={lockConsents.loading}
                onClick={() => {
                  setModalOpen(false);
                  document.body.style.overflow = "auto";
                }}
                variant={
                  theme.settings?.select?.texts?.selectNoFinishButton?.type ===
                  "link"
                    ? "link"
                    : "primary"
                }
                className="btn text-uppercase-first-letter text-center bg-white btn-block"
              >
                {theme.settings?.select?.texts?.selectNoFinishButton
                  ? getTextWithPopulatePlaceholders(
                      theme.settings.select.texts.selectNoFinishButton,
                      resources
                    )
                  : t("common.no")}
              </Button>
            </div>
          </div>
        </ModalWrapper>
      </Modal>
    </ScreenContentWrapper>
  );
};

export default SelectScreenV2;
