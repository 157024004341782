import { AxiosError } from "axios";
import { createContext } from "react";
import { BootstrapResponseOrNull, useBootstrap } from "../hooks/useBootstrap";

const ResourcesContext = createContext<{
  resources: BootstrapResponseOrNull;
  error: AxiosError | null;
}>({ resources: null, error: null });

const ResourcesProvider: React.FC = (props: unknown) => {
  const { resources, error } = useBootstrap();

  const value = { resources, error };

  return <ResourcesContext.Provider value={value} {...props} />;
};

export { ResourcesProvider, ResourcesContext };
