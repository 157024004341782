import parse from "html-react-parser";
import { find } from "lodash";
export const getTextWithPopulatePlaceholders = (
  setting: any,
  resources: any,
  dynamicPlaceholderValue?: string | null
) => {
  const placeholders = setting.placeholders;
  const values = setting.values;

  const populatedText = placeholders?.reduce(
    (acc: any, placeholder: any, index: any) =>
      acc.replace(placeholder, values[index]),
    setting[resources!.language]
  );

  if (setting?.dynamicPlaceholder) {
    return parse(
      (populatedText ?? setting[resources!.language]).replaceAll(
        setting.dynamicPlaceholder[0],
        dynamicPlaceholderValue
      )
    );
  }

  if (!populatedText) return setting[resources!.language];
  return parse(populatedText);
};

export const getResource = (
  data: { type: string; text: string }[],
  searchText: string
): string => {
  return (
    find(data, {
      type: searchText,
    })?.text || ""
  );
};

export const getTimeZoneId = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  switch (timezone) {
    case "Africa/Asmera":
      return "Africa/Asmara";
    case "America/Buenos_Aires":
      return "America/Argentina/Buenos_Aires";
    case "America/Catamarca":
      return "America/Argentina/Catamarca";
    case "America/Coral_Harbour":
      return "America/Atikokan";
    case "America/Cordoba":
      return "America/Argentina/Cordoba";
    case "America/Godthab":
      return "America/Nuuk";
    case "America/Indianapolis":
      return "America/Indiana/Indianapolis";
    case "America/Louisville":
      return "America/Kentucky/Louisville";
    case "America/Jujuy":
      return "America/Argentina/Jujuy";
    case "America/Mendoza":
      return "America/Argentina/Mendoza";
    case "Asia/Calcutta":
      return "Asia/Kolkata";
    case "Asia/Katmandu":
      return "Asia/Kathmandu";
    case "Asia/Rangoon":
      return "Asia/Yangon";
    case "Asia/Saigon":
      return "Asia/Ho_Chi_Minh";
    case "Atlantic/Faeroe":
      return "Atlantic/Faroe";
    case "Europe/Kiev":
      return "Europe/Kyiv";
    case "Pacific/Enderbury":
      return "Pacific/Kanton";
    case "Pacific/Ponape":
      return "Pacific/Pohnpei";
    case "Pacific/Truk":
      return "Pacific/Chuuk";
    default:
      return timezone;
  }
};
