import { createGlobalStyle, css } from "styled-components/macro";

const toStyleAttribute = (obj) => {
  console.log({ obj });
  const test = Object.keys(obj)
    .map((key) => {
      // Camel case property names to CSS selector names.
      return key.replace(/([A-Z])/g, "-$1").toLowerCase() + ":" + obj[key];
    })
    .join(";");

  console.log({ test });
  return test;
};

export const GlobalStyles = createGlobalStyle`

  @font-face {
    font-family: ${({ theme }) =>
      `${theme.fontFamily ? theme.fontFamily : theme.font}`};
    src: ${({ theme }) => `url(${theme.font}) format(${theme.fontType})`};
  }
  @font-face {
    font-family: ${({ theme }) =>
      `${
        theme.fontFamilyForTitles
          ? theme.fontFamilyForTitles
          : theme.fontForTitles
      }`};
    src: ${({ theme }) =>
      `url(${theme.fontForTitles}) format(${theme.fontTypeForTitles})`};
  }
  * {
    font-family: ${({ theme }) =>
      `${theme.fontFamily ? theme.fontFamily : theme.font}`};
  }

  .with-custom-font {
    ${(props) =>
      props.theme.fontFamilyForTitles &&
      css`
        font-family: ${({ theme }) =>
          `${
            theme.fontFamilyForTitles
              ? theme.fontFamilyForTitles
              : theme.fontForTitles
          }`};
      `}
  }
  body {
    background: ${({ theme }) => theme.colors.body};
    color: ${({ theme }) => theme.colors.text};
    // font-family: ${({ theme }) => theme.font};
    transition: all 0.50s linear;
  }

  p {
    color: ${({ theme }) => theme.colors.text};
  }
  .accent-text {
    color: ${(props) => props.theme.colors.secondaryAccentText};
  }
  .btn {
    padding: ${(props) => props.theme.colors.buttons?.padding};
  }
  button[type=button] {
    border-radius: ${(props) => props.theme.colors.buttons.borderRadius};
    &:before{
      border-radius: ${(props) => props.theme.colors.buttons.borderRadius};
    }
    
  }

  svg:not(.chevron) {
    fill: ${(props) => props?.theme?.colors?.icons?.default};
    color: ${(props) => props?.theme?.colors?.icons?.default};
  }
  
  .modal-body {
    border-radius: ${(props) => props?.theme?.settings?.popupsBorderRadius};
  }

  .modal-header{
    border: none;
  }
   .modal-title {
    ${(props) =>
      props?.theme?.settings?.modals?.modalTitle &&
      toStyleAttribute(props?.theme?.settings?.modals?.modalTitle?.styles)}   
    }
    .modal-subtitle {
    ${(props) =>
      props?.theme?.settings?.modals?.modalSubTitle &&
      toStyleAttribute(props?.theme?.settings?.modals?.modalSubTitle?.styles)}
    }
`;
