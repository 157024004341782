import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { useTheme, css } from "styled-components";
import { LoadableButton } from "../../../components/button/loadable-button.component";

import { Footer, FooterContainer } from "../../layout/screens.layout";
import { scrollTop } from "../../../utils/scrollTop";
import { useHistory } from "react-router";
import { useAcceptConsent } from "../../../hooks/useAcceptConsent";
import { SnackbarContext } from "../../../context/snackbar";
import { getTextWithPopulatePlaceholders } from "../../../utils/helpers";
import { ResourcesContext } from "../../../context/consumer";
import Modal from "../../../components/modal/Modal";

import { Button, ModalWrapper } from "../../select/select";
import { SelectedBankContext } from "../../../context/selectedBank";
import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const ButtonsFooter = styled(Footer)`
  height: auto;
  ${(props) =>
    props.theme.settings.consent.layout === "v1" &&
    css`
      justify-content: flex-end;
      @media (max-width: 780px) {
        justify-content: center;
      }
    `}
`;
const ButtonsFooterContainer = styled(FooterContainer)<{ maxWidth: string }>`
  display: flex;
  padding: 8px 0;
  justify-content: space-between;
  max-width: ${({ maxWidth }) => maxWidth ?? "500px"};
  ${(props) =>
    props.theme.settings.consent.layout === "v1" &&
    css`
      justify-content: flex-end;

      align-items: center;
      gap: 0.5rem;
      @media (max-width: 780px) {
        flex-direction: column;
        button {
          max-width: none;
          width: 100%;
          margin-right: 0;
        }
      }
    `}
  width: 100%;
  ${(props) =>
    props.theme.settings.consent.layout === "v2" &&
    css`
      flex-direction: column;
    `}
  gap: 1rem;
  & .btn {
    padding: 0.8rem 3rem;
    margin-top: 0;
  }

  @media (max-width: 640px) {
    width: 100%;
  }
  ${(props) =>
    props.theme.settings.consent.layout === "v1" &&
    css`
      justify-content: end;

      @media (max-width: 780px) {
        justify-content: center;
        flex-direction: column;
        button {
          max-width: none;
          width: 100%;
          margin-right: 0;
        }
      }
    `}
`;
function ButtonsComponent({
  rejectConsentLoading,
  rejectConsent,
  consent,
  agree,
  consentToken,
  rejectConsentError,
}: any) {
  const { t } = useTranslation();
  const theme: any = useTheme();
  const history = useHistory();
  const { bank } = useContext(SelectedBankContext);
  const { execute, error, loading } = useAcceptConsent(consentToken);
  const { openSnackbar } = useContext(SnackbarContext);
  const { resources } = useContext(ResourcesContext);
  const [showModal, setShowModal] = useState(false);
  const modal = useRef(null);
  useEffect(() => {
    if (error || rejectConsentError) {
      openSnackbar(true);
    }
  }, [error, rejectConsentError, openSnackbar]);

  return (
    <ButtonsFooter
      style={{ bottom: "1.5rem" }}
      className={`mt-4 consent ${
        theme.settings.intro.layout == "v2" && "bg-none"
      }`}
    >
      <ButtonsFooterContainer
        maxWidth={
          theme.settings.consent?.texts?.consentBackButton?.type === "link" &&
          theme.settings.consent.layout === "v1"
            ? "none"
            : "500px"
        }
      >
        <LoadableButton
          id="consentReject"
          isLoading={rejectConsentLoading}
          disabled={rejectConsentLoading || loading}
          style={{
            maxWidth:
              theme.settings.consent?.texts?.consentBackButton?.type ===
                "link" && theme.settings.consent.layout === "v1"
                ? "max-content"
                : "none",
          }}
          className={`${
            !theme?.colors?.buttons?.secondary ? " exp-outline-default" : ""
          } btn text-uppercase-first-letter text-center  btn-block`}
          onClick={() => {
            rejectConsent(() => scrollTop(() => history.goBack()));
          }}
          variant={
            theme.settings.consent?.texts?.consentBackButton?.type === "link"
              ? "link"
              : "secondary"
          }
        >
          {theme.settings?.consent?.texts?.consentBackButton
            ? getTextWithPopulatePlaceholders(
                theme.settings.consent.texts.consentBackButton,
                resources
              )
            : t("common.back")}
        </LoadableButton>
        <LoadableButton
          id="consentContinue"
          isLoading={loading}
          style={{
            maxWidth:
              theme.settings.consent.layout === "v1" &&
              theme.settings.consent?.texts?.consentContinueButton?.type ===
                "link"
                ? "250px"
                : "none",
          }}
          variant={
            theme.settings.consent?.texts?.consentContinueButton?.type ===
            "link"
              ? "link"
              : "primary"
          }
          className={`${
            !theme?.colors?.buttons?.secondary ? "exp-default bg-white" : ""
          } btn text-uppercase-first-letter text-center  btn-block`}
          disabled={!consent || !agree || loading || rejectConsentLoading}
          onClick={() => {
            if (bank?.consentInstructionText) {
              setShowModal(true);
            } else {
              execute((url: string) => {
                window.location.href = url;
              });
            }
          }}
        >
          {theme.settings?.consent?.texts?.consentContinueButton
            ? getTextWithPopulatePlaceholders(
                theme.settings.consent.texts.consentContinueButton,
                resources
              )
            : t("common.continueToProvider")}
        </LoadableButton>
      </ButtonsFooterContainer>

      <Modal>
        <ModalWrapper className={showModal ? "visible" : ""} ref={modal}>
          <div className="modal-body">
            <div className="modal-header">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
              >
                <path d="M19.56 1.09998H19.5C14.5166 1.22166 9.77874 3.28879 6.3 6.85912C2.82126 10.4295 0.877926 15.2194 0.885766 20.2043C0.893606 25.1892 2.85199 29.973 6.34195 33.5324C9.8319 37.0918 14.5763 39.144 19.56 39.25H19.61C24.5934 39.1295 29.3317 37.0636 32.8113 33.4942C36.2909 29.9248 38.2355 25.1353 38.2289 20.1505C38.2224 15.1657 36.2653 10.3814 32.7763 6.82114C29.2874 3.26086 24.5437 1.20737 19.56 1.09998ZM19.56 37.1C15.2894 36.7717 11.3002 34.8435 8.38993 31.701C5.47966 28.5584 3.86288 24.4331 3.86288 20.15C3.86288 15.8668 5.47966 11.7415 8.38993 8.59898C11.3002 5.45642 15.2894 3.52824 19.56 3.19998C23.8305 3.52824 27.8198 5.45642 30.7301 8.59898C33.6403 11.7415 35.2571 15.8668 35.2571 20.15C35.2571 24.4331 33.6403 28.5584 30.7301 31.701C27.8198 34.8435 23.8305 36.7717 19.56 37.1Z" />
                <path d="M19.56 6.62001C18.8476 6.61803 18.1506 6.82748 17.5572 7.22185C16.9639 7.61622 16.5009 8.17777 16.2269 8.83541C15.9529 9.49304 15.8802 10.2172 16.018 10.9162C16.1557 11.6152 16.4978 12.2576 17.0009 12.762C17.504 13.2665 18.1454 13.6104 18.844 13.7501C19.5426 13.8898 20.267 13.8191 20.9254 13.5469C21.5838 13.2747 22.1466 12.8133 22.5426 12.2211C22.9386 11.6289 23.15 10.9324 23.15 10.22C23.15 9.26696 22.7721 8.3528 22.0991 7.67795C21.4262 7.00311 20.5131 6.62266 19.56 6.62001Z" />
                <path d="M19.56 15.28C18.6131 15.2879 17.7076 15.6696 17.0409 16.342C16.3741 17.0144 16 17.9231 16 18.87V30.13C15.9742 30.6181 16.0481 31.1062 16.2172 31.5648C16.3862 32.0234 16.6469 32.4427 16.9832 32.7973C17.3196 33.1519 17.7246 33.4342 18.1736 33.6272C18.6227 33.8202 19.1063 33.9197 19.595 33.9197C20.0837 33.9197 20.5673 33.8202 21.0164 33.6272C21.4654 33.4342 21.8704 33.1519 22.2068 32.7973C22.5431 32.4427 22.8038 32.0234 22.9728 31.5648C23.1418 31.1062 23.2157 30.6181 23.19 30.13V18.87C23.19 18.3952 23.0959 17.9251 22.9129 17.4869C22.73 17.0487 22.462 16.6511 22.1244 16.3172C21.7868 15.9834 21.3863 15.7198 20.9461 15.5417C20.5059 15.3637 20.0348 15.2747 19.56 15.28Z" />
              </svg>

              <span
                className="close-btn"
                onClick={() => {
                  setShowModal(false);
                  document.body.style.overflow = "auto";
                }}
              >
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  size="lg"
                  color={
                    theme?.colors?.icons?.default ??
                    "var(--exp-dark-grey) !important"
                  }
                />
              </span>
            </div>
            <div>
              {" "}
              <h4>
                {bank?.consentInstructionText &&
                  parse(bank!.consentInstructionText)}
              </h4>
            </div>
            <div className="btns-wrapper">
              <Button
                id="yesExit"
                isLoading={false}
                disabled={false}
                onClick={() => {
                  execute((url: string) => {
                    window.location.href = url;
                  });
                }}
                className="btn exp-outline-default  text-uppercase-first-letter text-center bg-white btn-block"
                variant={
                  theme.settings?.consent?.texts?.consentDefaultInstructionModal
                    ?.continueButton?.type === "link"
                    ? "link"
                    : "primary"
                }
              >
                {theme.settings?.consent?.texts?.consentDefaultInstructionModal
                  ?.continueButton
                  ? getTextWithPopulatePlaceholders(
                      theme.settings.consent.texts
                        .consentDefaultInstructionModal?.continueButton,
                      resources
                    )
                  : t("common.continue")}
              </Button>
            </div>
          </div>
        </ModalWrapper>
      </Modal>
    </ButtonsFooter>
  );
}

export default ButtonsComponent;
