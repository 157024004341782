import { useEffect, useRef, useContext, useState } from "react";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { usePrivacyPolicy } from "../../hooks/usePrivacyPolicy";
import Loader from "../loading/loading-tc.component";
import TryAgain from "../tryagain/tryagain.component";

import { ResourcesContext } from "../../context/consumer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { ReadMore } from "../terms/tandc.component";

const PrivacyPolicyContainer = styled.div`
  padding: 8px 0 0 8px;
`;

const PrivacyPolicyBody = styled.div`
  height: 300px;
  overflow-y: scroll;
  position: relative;
  scroll-behavior: smooth;
  p {
    color: ${(props) => props.theme.colors.text};
  }
  h3,
  h4 {
    font-weight: bold;
  }
  .text-underline {
    text-decoration: underline;
  }
  h4 {
    text-align: left !important;
    font-size: 1.8rem !important;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  ::-webkit-scrollbar:vertical {
    width: 8px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(183, 183, 183, 0.5);
  }
  ::-webkit-scrollbar-track {
    background-color: rgb(239, 239, 239);
  }
`;

const PrivacyPolicyAction = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;

  & .spinner-border {
    width: 3rem !important;
    height: 3rem !important;
  }
`;

const PrivacyPolicy: React.FC<any> = ({ loadingPrivacyPolicy, bodyStyle }) => {
  const { resources } = useContext(ResourcesContext);
  const {
    privacyPolicy: { text },
    loading,
    error,
    refetch,
  } = usePrivacyPolicy(resources?.termsAccepted);

  const [showScrollReadMore, setShowScrollReadMore] = useState(true);
  const privacyPolicyTextRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (!loading && loadingPrivacyPolicy) {
      loadingPrivacyPolicy();
    }
  }, [loading]);

  const { t } = useTranslation();

  const handleScroll = (e: any) => {
    const container = e.target;
    if (
      container.scrollHeight - container.scrollTop - container.clientHeight <
      2
    ) {
      setShowScrollReadMore(false);
    }
  };
  return (
    <div style={bodyStyle ?? { position: "relative", height: "332px" }}>
      {loading && (
        <PrivacyPolicyAction>
          <Loader />
        </PrivacyPolicyAction>
      )}
      {!loading ? (
        <PrivacyPolicyContainer>
          <PrivacyPolicyBody
            onScroll={handleScroll}
            style={bodyStyle}
            tabIndex={0}
            id="privacyPolicyContainer"
          >
            {error && (
              <PrivacyPolicyAction>
                <TryAgain refetch={() => refetch()} />
              </PrivacyPolicyAction>
            )}
            <div
              style={{
                wordBreak: "break-word",
                paddingRight: "10px",
              }}
              id="introPrivacyPolicyText"
              ref={privacyPolicyTextRef}
            >
              {parse(text)}
            </div>

            {text && showScrollReadMore && (
              <ReadMore>
                <span>
                  {t("introScreen.scrollDownLabel")}{" "}
                  <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>
                </span>
              </ReadMore>
            )}
          </PrivacyPolicyBody>
        </PrivacyPolicyContainer>
      ) : (
        <PrivacyPolicyContainer>
          <div style={bodyStyle}></div>
        </PrivacyPolicyContainer>
      )}
    </div>
  );
};

export default PrivacyPolicy;
