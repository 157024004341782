import React, { useContext } from "react";
import styled, { useTheme } from "styled-components/macro";
import ExperianLogo from "../../assets/ExperianLogo.png";
import { ReactComponent as FailJourneyIcon } from "../../assets/failJourneyIcon.svg";
import { ReactComponent as SuccesJouneyIcon } from "../../assets/successJourneyIcon.svg";
import { ResourcesContext } from "../../context/consumer";
import { TextResourcesContext } from "../../context/textResourcesContext";
import useScreenSize from "../../hooks/useScreenSize";
import { useTextResource } from "../../hooks/useTextResource";
import { getResource } from "../../utils/helpers";
//containers
import {
  ScreenContainer,
  Content,
  ScreenContentWrapper,
} from "../layout/screens.layout";

const ContentContainer = styled.div`
  min-height: 350px;
  position: relative;
  display: flex;
`;

const IconContainer = styled.div`
  margin-top: 16px;
  text-align: center;
  margin-bottom: 26px;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 425px) {
    flex-direction: column;
  }
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 425px) {
    align-items: center;
    margin-top: 2.6rem;
  }
`;

const Message = styled.div`
  font-size: 2.3rem;
`;

const Logo = styled.div`
  display: flex;
  flex-direction: column;
  & img {
    height: 3.7rem;
    width: 11rem;
  }
  @media (max-width: 425px) {
    margin-top: 2.6rem;
  }
`;

interface FinishScreenProps {
  status: "fail" | "success";
}

const FinishScreen = ({ status }: FinishScreenProps) => {
  const { data } = useContext(TextResourcesContext);
  console.log({ data });

  const theme: any = useTheme();
  const { resources } = useContext(ResourcesContext);
  const screenSize = useScreenSize();
  return (
    <ScreenContentWrapper
      $currentScreenWidth={screenSize.width}
      style={{
        ...theme.settings.screenContainer,
        minHeight: "auto",
        minWidth: "50vw",
      }}
    >
      <ScreenContainer>
        {theme?.settings.customBackground ? (
          <div
            className="custom-bg"
            style={theme.settings.customBackground?.styles}
          >
            {" "}
          </div>
        ) : null}
        <Content>
          <ContentContainer>
            <ContentWrapper>
              <IconContainer>
                {status === "success" ? (
                  <SuccesJouneyIcon
                    className="mr-3"
                    style={{ height: "9rem", minWidth: "40px" }}
                  />
                ) : (
                  <FailJourneyIcon
                    className="mr-3"
                    style={{ height: "9rem", minWidth: "40px" }}
                  />
                )}
              </IconContainer>
              <MessageContainer>
                <Message
                  className="modal-title mb-4"
                  style={{ whiteSpace: "pre-line" }}
                >
                  {getResource(
                    data,
                    status === "fail"
                      ? "CJ_finish_FailMessage"
                      : "CJ_finish_SuccessMessage"
                  ).replace("{lenderName}", resources!.lender?.name)}
                </Message>
                <Logo>
                  <img
                    alt="Experian logo"
                    className="experian-logo"
                    src={ExperianLogo}
                  />
                </Logo>
              </MessageContainer>
            </ContentWrapper>
          </ContentContainer>
        </Content>
      </ScreenContainer>
    </ScreenContentWrapper>
  );
};

export default FinishScreen;
