import styled, { useTheme } from "styled-components";
import { useEffect, useState, useContext } from "react";
import Modal from "../../modal/Modal";
import TermsAndConditions from "../tandc.component";
import { LoadableButton } from "../../button/loadable-button.component";
import { useAcceptTerms } from "../../../hooks/useAcceptTerms";
import { useOrchestrationToken } from "../../../hooks/useOrchestrationToken";
import { scrollTop } from "../../../utils/scrollTop";
import { useHistory } from "react-router";
import useMediaQuery from "../../../hooks/useMediaQuery";
import { useDownloadTerms } from "../../../hooks/useDownloadTerms";
import Snackbar from "../../../containers/snackbar";
import { useTranslation } from "react-i18next";
import {
  getResource,
  getTextWithPopulatePlaceholders,
} from "../../../utils/helpers";
import { ResourcesContext } from "../../../context/consumer";
import { TextResourcesContext } from "../../../context/textResourcesContext";

interface Props {
  acceptTermsAndConditions: () => void;
  closeTermsAndConditions: () => void;
}

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1001;
  display: flex;
  justify-content: center;
  overflow: hidden;

  .modal-body {
    width: 100vw;
    height: 100vh;
    background: #fff;
    display: flex;
    flex-direction: column;
    border-radius: 0px !important;
    h4 {
      font-size: 20px;
      width: 100%;
      text-align: center;
      margin: 5px 0 15px;
    }

    .btns-wrapper {
      max-width: 430px;
      display: flex;

      justify-content: center;
      flex-direction: column;
      margin: 2rem auto;
      width: 100%;
      align-items: center;
      gap: 1rem;
      button {
        margin: 0;
      }

      .buttons-container {
        display: flex;
        gap: 1rem;
        flex-direction: column;
        width: 100%;
      }
    }
  }

  .modal-header {
    display: flex;
    justify-content: space-between;

    .close-btn {
      cursor: pointer;
    }
  }
`;

const ActionButtonAnchor = styled(LoadableButton)`
  width: 100%;

  font-size: 1.7rem !important;

  margin-bottom: 1rem;

  @media (max-width: 320px) {
    padding: 0.8rem 1.8rem !important;
  }
`;

const TermsAndConditionsModal: React.FC<Props> = ({
  acceptTermsAndConditions,
  closeTermsAndConditions,
}) => {
  const [termsFinishLoading, setTermsFinishLoading] = useState(true);
  const [isErrorDownloadPdf, setIsErrorDownloadPdf] = useState(false);
  const { t } = useTranslation();
  const orchestrationToken = useOrchestrationToken();
  const history = useHistory();
  const theme: any = useTheme();
  const { resources } = useContext(ResourcesContext);

  const { loading, refetch, error } = useDownloadTerms();
  const { data } = useContext(TextResourcesContext);
  const isMobile = useMediaQuery("(max-width: 425px)");
  const { loading: loadingAcceptTerms, error: errorAcceptTerms } =
    useAcceptTerms(() =>
      scrollTop(() =>
        history.push(`/select?orchestrationToken=${orchestrationToken}`)
      )
    );
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const loadingTermsAndConditions = () => {
    setTermsFinishLoading(false);
  };

  return (
    <Modal>
      <ModalWrapper>
        <div className="modal-body">
          <TermsAndConditions
            loadingTerms={loadingTermsAndConditions}
            bodyStyle={{
              maxWidth: isMobile ? "42rem" : "768px",
              margin: "auto",
              height: "calc(100vh - 27rem)",
            }}
          />
          <div className="btns-wrapper">
            <div className="buttons-container">
              <ActionButtonAnchor
                id="introReadTermsDownload"
                disabled={termsFinishLoading}
                isLoading={loading}
                className={`btn ${
                  error ? "btn-exp-red" : "bg-white exp-outline-default"
                }   `}
                onClick={refetch}
                variant={
                  theme.settings?.intro?.texts?.introReadTermsDownloadButton
                    ?.type === "link"
                    ? "link"
                    : "secondary"
                }
              >
                {getResource(data, "CJ_introScreen_TCDownload_Button")}
              </ActionButtonAnchor>
              <ActionButtonAnchor
                id="introReadTermsDecline"
                disabled={termsFinishLoading}
                isLoading={false}
                className="btn text-uppercase-first-letter"
                onClick={closeTermsAndConditions}
                variant={
                  theme.settings?.intro?.texts?.introReadTermsDeclineButton
                    ?.type === "link"
                    ? "link"
                    : "primary"
                }
              >
                {getResource(data, "CJ_introScreen_TCDecline_Button")}
              </ActionButtonAnchor>
              <ActionButtonAnchor
                id="introReadTermsAccept"
                disabled={termsFinishLoading}
                isLoading={false}
                className="btn text-uppercase-first-letter"
                onClick={acceptTermsAndConditions}
                variant={
                  theme.settings?.intro?.texts?.introReadTermsAcceptButton
                    ?.type === "link"
                    ? "link"
                    : "primary"
                }
              >
                {getResource(data, "CJ_introScreen_TCAccept_Button")}
              </ActionButtonAnchor>
            </div>
          </div>
        </div>
      </ModalWrapper>
      <Snackbar />
    </Modal>
  );
};

export default TermsAndConditionsModal;
