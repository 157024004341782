import styled from "styled-components/macro";

const ScreenContentWrapper = styled.div<{ $currentScreenWidth: number }>`
  
  max-width: ${(props) =>
    props.theme?.settings?.screenContainer?.padding
      ? `calc(810px + 2* ${props.theme?.settings?.screenContainer?.padding})`
      : "calc(810px + (2 * 2rem))"};
  margin: auto;
  // width: 100%;
  background: ${(props) => props.theme.colors.body};
  padding: ${(props) =>
    props.theme?.settings?.screenContainer?.padding ?? "2rem"};
  border-radius: 20px;
  border: ${(props) =>
    props.theme.settings?.header?.visible ? "1px solid #ffffff" : "none"};
  box-shadow: 1px 2px 10px #c9c9c9;
  overflow: hidden;
  @media (max-width: 992px) {
    
    width:${(props) =>
      `calc(${props.$currentScreenWidth}px - 2 * ${
        props.theme?.settings?.screenContainer?.padding || "2rem"
      })`};
  }
   @media (max-width: 428px) {
    
    padding:1rem !important;
    width:${(props) => `calc(${props.$currentScreenWidth}px - 2rem)`};
  }
  @media (min-width: 992px) {
    width: ${(props) =>
      props.theme?.settings?.screenContainer?.padding
        ? `calc(810px + 2* ${props.theme?.settings?.screenContainer?.padding})`
        : "calc(810px + (2 * 2rem))"};
    }
  }
`;

const ScreenContainer = styled.div`
  width: 100%;
  //min-height: calc(100vh - 76px);
  display: flex;
  flex-direction: column;
  // position: absolute;
  // left: 50%;
  // transform: translate(-50%, 0);
`;

const Content = styled.div`
  width: 100%;
  max-width: 810px;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  margin: 2rem auto;
  margin-bottom: 0;
  // flex: 2;
  justify-content: center;
  p {
    margin-bottom: 1.5rem;
  }
`;

const Footer = styled.div`
  background: ${(props) => props.theme?.colors?.buttons?.container?.background};
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;

  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 15px;

  &.bg-none {
    background: none !important;
  }
`;

const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  max-width: 500px;
  @media (max-width: 640px) {
    width: 100%;
    margin: 0 auto;
    // padding: 0 7.5%;
  }
`;

const ActionFooterWrapper = styled.div`
  margin-bottom: 1.5rem;
`;

const ActionFooter = styled.div`
  background: ${(props) => props.theme?.colors.buttons?.container?.background};
  /* padding: 0 7.5%; */

  position: relative;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 10px;
  position: relative;
  &.download {
    button {
      background: #fff;
      color: #26478d !important;
      border: 1px solid #26478d;
      &:hover {
        color: #fff !important;
      }
      &:disabled {
        color: #26478d !important;
      }
    }
  }

  &.bg-none {
    background: none !important;
  }
`;

const ActionFooterContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
  max-width: 810px;
  margin: 0 auto;
  text-align: center;
  & p {
    margin-bottom: 0;
    font-size: 1.6rem;
    line-height: 1.8rem;
    margin-left: 8px;
    text-align: left;
  }
  & span {
    /* width: 100%; */
    /* text-align: center; */
  }
`;

export {
  ScreenContentWrapper,
  ScreenContainer,
  Footer,
  FooterContainer,
  Content,
  ActionFooter,
  ActionFooterContainer,
  ActionFooterWrapper,
};
